import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import GSAP from 'react-gsap-enhancer'
import { gsap } from 'gsap'

class ToolTip extends Component {
  static propTypes = {
    tooltips: PropTypes.array
  }

  constructor(props) {
    super(props)

    this.showAnim = this.showAnim.bind(this)
  }

  componentDidMount() {
    this.showAnim = this.addAnimation(this.showAnim)
    this.showAnim.pause()
  }

  show() {
    this.showAnim.paused(false)
    this.showAnim.reversed(false)
  }

  hide() {
    this.showAnim.paused(false)
    this.showAnim.reversed(true)
  }

  showAnim({ target }) {
    let triangle = target.find({ name: 'triangle' })
    let tooltip = target.find({ name: 'tooltip' })
    return gsap.timeline({ repeat: 0 })
      .to(tooltip, 0.1, {
        width: '100%',
        padding: '20px',
        height: 'auto',
        autoAlpha: 1
      })
      .to(triangle, 0.05, { top: '15px' })
      .to(triangle, 0.05, { rotation: 90, top: '0px', left: '15px' })
      .to(triangle, 0.05, { left: '200px' })
      .to(triangle, 0.1, { autoAlpha: 1 }, '-=0.2')
  }

  render() {
    const { tooltips = [] } = this.props
    const text = tooltips.map(tooltip => (
      <div key={tooltip} className='tooltip__item'>
        <nobr>{tooltip}</nobr>
      </div>
    ))
    return (
      <div className={'tooltiped'}>
        <div
          onMouseEnter={this.show.bind(this)}
          onMouseLeave={this.hide.bind(this)}
        >
          {this.props.children}
        </div>
        {text && text.length > 0 ? (
          <div className='tooltip' name='tooltip'>
            <div className='triangle' name='triangle' />
            {text}
          </div>
        ) : (
          undefined
        )}
      </div>
    )
  }
}

export default GSAP()(ToolTip);
