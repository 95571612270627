import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './style.scss'

class Button extends Component {
  static propTypes = {
    handleClick: PropTypes.func,
    link: PropTypes.string,
    text: PropTypes.string,
    additionalClass: PropTypes.string,
    iconSrc: PropTypes.string
  }

  render() {
    const {
      handleClick = () => {
      },
      text = '',
      iconSrc = '',
      additionalClass = '',
      link = undefined,
      target = false
    } = this.props

    if (target) {
      return (
        <a
          className={
            'btn ' +
            (additionalClass ? additionalClass : '') +
            (iconSrc ? '' : ' no-icon')
          }
          onClick={handleClick}
          href={link}
          target={target}
          rel='nofollow noopener noreferrer'
        >
          <span className='btn-text'>{text}</span>
          {this.props.children ? (
            this.props.children
          ) : iconSrc ? (
            <img className='btn-icon' src={iconSrc} alt={text} />
          ) : (
            undefined
          )}
        </a>
      )
    } else if (link) {
      return (
        <Link
          className={
            'btn ' +
            (additionalClass ? additionalClass : '') +
            (iconSrc ? '' : ' no-icon')
          }
          to={link}
          onClick={handleClick}
        >
          <span className='btn-text'>{text}</span>
          {this.props.children ? (
            this.props.children
          ) : iconSrc ? (
            <img className='btn-icon' src={iconSrc} alt={text} />
          ) : (
            undefined
          )}
        </Link>
      )
    } else {
      return (
        <div
          className={
            'btn ' +
            (additionalClass ? additionalClass : '') +
            (iconSrc ? '' : ' no-icon')
          }
          onClick={handleClick}
        >
          <span className='btn-text'>{text}</span>
          {this.props.children ? (
            this.props.children
          ) : iconSrc ? (
            <img className='btn-icon' src={iconSrc} alt={text} />
          ) : (
            undefined
          )}
        </div>
      )
    }
  }
}

export default Button
