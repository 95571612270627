import {
  EDIT_ME_SUCCESS,
  GET_CONFIRM_FAILURE,
  GET_CONFIRM_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  PHONECONFIRM_FAILURE,
  PHONECONFIRM_SUCCESS,
  REGISTRATION_FAILURE,
  REGISTRATION_SUCCESS,
  RESTORE_COMPLETE_FAILURE,
  RESTORE_COMPLETE_SUCCESS,
  RESTORE_FAILURE
} from '../actions/user'

import { CLOSE_INFO_POPUP } from '../actions'

import { POST_CURATOR_FAILURE, POST_CURATOR_SUCCESS } from '../actions/curator'

import { GET_TEST_QESTION_FAILURE } from '../actions/test'

import { POST_HELP_REQUEST_FAILURE, POST_HELP_REQUEST_SUCCESS } from '../actions/funds'

import { SEND_FEEDBACK_FAILURE, SEND_FEEDBACK_SUCCESS } from '../actions/feedback'

import { SEND_PARTNERS_FAILURE, SEND_PARTNERS_SUCCESS } from '../actions/partners'

const initialState = {
  opened: false,
  message: '',
  type: 'success'
}

export const infoPopup = (state = initialState, action) => {
  const { type } = action

  if (action.payload?.status === 403 && action?.payload?.response?.message === 'Expired token' ) {
    return {
      ...state,
      opened: true,
      message: 'Вы слишком долго отсутствовали. Требуется повторная авторизация',
      type: 'error',
      openTypeOfForm: 'login',
      customCloseButtonText: 'Перейти к авторизации'
    }
  }

  switch (type) {
    case LOGIN_SUCCESS:
      if (
        action.payload &&
        action.payload.message &&
        action.payload.message.indexOf('error') !== -1
      ) {
        return {
          ...state,
          opened: true,
          message: 'Не удалось войти.',
          type: 'error'
        }
      } else if (
        action &&
        action.payload &&
        action.payload.phone_confirmed === false
      ) {
        return {
          ...state,
          opened: true,
          message: 'Введите код из SMS',
          text:
            'Для дальнейшей авторизации вам необходимо подтвердить номер телефона',
          type: 'confirm'
        }
      } else {
        window.dataLayer.push({
          event: 'event',
          eventAction: 'auth',
          eventLabel: 'success'
        })
        return { ...state }
      }
    case REGISTRATION_FAILURE:
      let errormessage = 'Не удалось зарегистрироваться. '
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.data
      ) {
        const errorObj = action.payload.response.data
        if (errorObj.email) {
          errormessage += 'Email не подходит. '
        }
        if (errorObj.phone) {
          errormessage += 'Телефон не подходит. '
        }
      }
      return {
        ...state,
        opened: true,
        message: errormessage,
        type: 'error'
      }
    case LOGIN_FAILURE:
    case POST_HELP_REQUEST_FAILURE:
      return {
        ...state,
        opened: true,
        message: 'Что-то пошло не так',
        type: 'error'
      }
    case EDIT_ME_SUCCESS:
      return {
        ...state,
        opened: true,
        message: 'Данные успешно обновлены',
        type: 'success'
      }
    case REGISTRATION_SUCCESS:
      let needConfirm = 'success'
      if (action && action.payload && !action.payload.phone_confirmed) {
        needConfirm = 'confirm'
      }
      return {
        ...state,
        opened: true,
        message: 'Введите код из SMS',
        type: needConfirm
      }
    case PHONECONFIRM_FAILURE:
      return {
        ...state,
        opened: true,
        message: 'Ошибка! Что-то пошло не так.',
        type: 'success'
      }
    case PHONECONFIRM_SUCCESS:
      return {
        ...state,
        opened: true,
        message: 'Телефон успешно подтвержден',
        type: 'success'
      }
    case GET_CONFIRM_SUCCESS:
      return {
        ...state,
        opened: true,
        message: 'Ваш аккаунт успешно подтвержден!',
        type: 'success'
      }
    case GET_CONFIRM_FAILURE:
      return {
        ...state,
        opened: true,
        message: 'Ошибка! Что-то пошло не так.',
        type: 'success'
      }
    case RESTORE_COMPLETE_SUCCESS:
      return {
        ...state,
        opened: true,
        message: 'Вы успешно поменяли пароль!',
        type: 'success',
        openTypeOfForm: 'login'
      }
    case RESTORE_COMPLETE_FAILURE:
      return {
        ...state,
        opened: true,
        message: 'Ошибка, не удалось изменить пароль',
        type: 'error'
      }
    // case RESTORE_SUCCESS:
    //   return {
    //     ...state,
    //     opened: true,
    //     message: 'Введите код из SMS и ваш новый пароль',
    //     type: 'restore'
    //   }
    case RESTORE_FAILURE:
      return {
        ...state,
        opened: true,
        message: 'Ошибка, не удалось отправить письмо вам на почту',
        type: 'success'
      }
    case POST_HELP_REQUEST_SUCCESS:
      return {
        ...state,
        opened: true,
        message: 'Заявка отправлена!',
        type: 'success'
      }
    case SEND_FEEDBACK_SUCCESS:
      return {
        ...state,
        opened: true,
        message: 'Заявка отправлена!',
        type: 'success'
      }
    case SEND_FEEDBACK_FAILURE:
      return {
        ...state,
        opened: true,
        message: 'Ошибка. Попробуйте ещё раз.',
        type: 'error'
      }
    case SEND_PARTNERS_SUCCESS:
      return {
        ...state,
        opened: true,
        message: 'Спасибо! Ваша заявка отправлена.',
        type: 'success'
      }
    case SEND_PARTNERS_FAILURE:
      return {
        ...state,
        opened: true,
        message: 'Ошибка. Попробуйте ещё раз.',
        type: 'error'
      }
    case CLOSE_INFO_POPUP:
      return {
        ...state,
        opened: false,
        message: '',
        type: 'success',
        openTypeOfForm: ''
      }
    case GET_TEST_QESTION_FAILURE:
      return {
        ...state,
        opened: true,
        message: 'Не удалось начать тестирование.',
        type: 'error'
      }
    case POST_CURATOR_FAILURE:
      return {
        ...state,
        opened: true,
        message: 'Не удалось отправить заявку куратору',
        type: 'error'
      }
    case POST_CURATOR_SUCCESS:
      return {
        ...state,
        opened: true,
        message:
          'Ваша заявка принята, куратор питомца свяжется с вами в течение 3 дней. Вы также можете связаться с ним самостоятельно, письмо с номером телефона куратора отправлено на вашу почту',
        type: 'success'
      }

    default:
      return state
  }
}
