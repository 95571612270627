import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.scss'

class PopupPager extends Component {
  static propTypes = {
    pages: PropTypes.array.isRequired
  }

  state = {
    currentPage: 0
  }

  componentDidUpdate() {
    const page = this.props.pages.find(page => {
      return page.current
    })

    if (page && page.title) {
      this.refs.pager.style.left = this.refs[page.title].offsetLeft + 'px'
      this.refs.pager.style.top = this.refs[page.title].offsetTop + 37 + 'px'
      this.refs.pager.style.width = this.refs[page.title].clientWidth + 'px'
    }
  }

  render() {
    const { pages = [] } = this.props

    const pageItems = pages.map(page => (
      <div
        key={page.title}
        className={
          'popup-pager__item ' +
          (page.current ? ' active ' : '') +
          (page.wide ? ' item-wide ' : '')
        }
        role="button"
        onClick={page.action}
        ref={page.title}
      >
        {page.title}
      </div>
    ))

    return (
      <div className={'popup-pager'}>
        {pageItems}
        <div className="current-page-pager" ref="pager" />
      </div>
    )
  }
}

export default PopupPager
