export const noMatterYesNoSearchVariants = ({
  filterName,
  title
}) => {
  return {
    title,
    filterName,
    variants: [
      {
        title: 'Не важно',
        value: 'All'
      },
      {
        title: 'Да',
        value: 'yes'
      },
      {
        title: 'Нет',
        value: 'no'
      }
    ]
  }
}

export const yesNoNeutralSearchVariants = ({ filterName, title }) => {
  return {
    title,
    filterName,
    variants: [
      {
        title: 'Равнодушен',
        value: 'All'
      },
      {
        title: 'Да',
        value: 'yes'
      },
      {
        title: 'Нет',
        value: 'no'
      }
    ]
  }
}

export const petParamsList = {
  cityVariants: {
    title: 'Где вы находитесь',
    weight: 0,
    filterName: 'pet_city',
    variants: [
      {
        title: 'Выберите город',
        value: 'All'
      },
      {
        title: 'Брянск',
        value: '39',
        urlName: 'bry'
      },
      {
        title: 'Екатеринбург',
        value: '49',
        urlName: 'ekat'
      },
      {
        title: 'Краснодар',
        value: '32',
        urlName: 'krd'
      },
      {
        title: 'Ленинградская обл.',
        value: '35',
        urlName: 'lo'
      },
      {
        title: 'Москва',
        value: '11',
        urlName: 'msk'
      },
      {
        title: 'Московская область',
        value: '20',
        urlName: 'mo'
      },
      {
        title: 'Мурманск',
        value: '45',
        urlName: 'mur'
      },
      {
        title: 'Нижний Новгород',
        value: '29',
        urlName: 'nnv'
      },
      {
        title: 'Санкт-Петербург',
        value: '23',
        urlName: 'spb'
      },
      {
        title: 'Тверская область',
        value: '55',
        urlName: 'to'
      },
      {
        title: 'Калужская область',
        value: '51',
        urlName: 'ko'
      },
      {
        title: 'Красноярск',
        value: '47',
        urlName: 'kry'
      },
      {
        title: 'Казань',
        value: '60',
        urlName: 'kazan'
      },
      {
        title: 'Обнинск',
        value: '58',
        urlName: 'obninsk'
      },
      {
        title: 'Пермь',
        value: '59',
        urlName: 'perm'
      },
      {
        title: 'Саратов',
        value: '61',
        urlName: 'sar'
      },
      {
        title: 'Сочи',
        value: '41',
        urlName: 'sochi'
      }
    ]
  },
  fondVariants: {
    title: 'Фонд/приют',
    filterName: 'pet_fond',
    weight: 1,
    variants: [
      {
        title: 'Не важно',
        value: 'All'
      },
      {
        title: 'Би Хеппи',
        value: '6597'
      },
    ]
  },
  typeVariants: {
    title: 'Кого вы ищите',
    filterName: 'pet_type',
    weight: 1,
    variants: [
      {
        title: 'Не важно',
        value: 'All'
      },
      {
        title: 'Кошка',
        value: 'cat',
        urlName: 'cats'
      },
      {
        title: 'Собака',
        value: 'dog',
        urlName: 'dogs'
      }
    ]
  },
  ageVariants: {
    title: 'Возраст питомца',
    filterName: 'pet_age[]',
    weight: 2,
    variants: [
      {
        title: 'Любой',
        value: 'All'
      },
      {
        title: '0-1 год',
        value: '1',
        urlName: 'do-1-goda'
      },
      {
        title: '2-6  года',
        value: '2',
        urlName: '2-6-let'
      },
      {
        title: '7+ лет',
        value: '3',
        urlName: 'ot-7-let'
      }
    ]
  },
  sexVariants: {
    title: 'Пол питомца',
    filterName: 'pet_sex',
    weight: 3,
    variants: [
      {
        title: 'Любой',
        value: 'All'
      },
      {
        title: 'Мальчик',
        value: 'boy',
        urlName: 'malchiki'
      },
      {
        title: 'Девочка',
        value: 'girl',
        urlName: 'devochki'
      }
    ]
  },
  sizeVariants: {
    title: 'Размер питомца',
    filterName: 'pet_size[]',
    weight: 4,
    variants: [
      {
        title: 'Любой',
        value: 'All'
      },
      {
        title: 'Маленький',
        value: 'small',
        urlName: 'malenkie'
      },
      {
        title: 'Средний',
        value: 'middle',
        urlName: 'srednie'
      },
      {
        title: 'Большой',
        value: 'large',
        urlName: 'bolshye'
      }
    ]
  },
  hairVariants: {
    title: 'Шерсть',
    filterName: 'pet_hair',
    weight: 5,
    variants: [
      {
        title: 'Любая',
        value: 'All'
      },
      {
        title: 'Длинная',
        value: 'long',
        urlName: 'dlinnosherstnye'
      },
      {
        title: 'Короткая',
        value: 'short',
        urlName: 'korotkosherstnye'
      }
    ]
  },
  tempVariants: {
    title: 'Темперамент питомца',
    filterName: 'pet_temp',
    weight: 6,
    variants: [
      {
        title: 'Любой',
        value: 'All'
      },
      {
        title: 'Спокойный',
        value: 'calm',
        urlName: 'spokoinye'
      },
      {
        title: 'Активный',
        value: 'active',
        urlName: 'aktivnye'
      }
    ]
  },
  colorVariants: {
    title: 'Окрас питомца',
    filterName: 'pet_color[]',
    weight: 7,
    variants: [
      {
        title: 'Любой',
        value: 'All'
      },
      {
        title: 'Черный',
        value: 'black',
        urlName: 'chernye'
      },
      {
        title: 'Коричневый',
        value: 'brown',
        urlName: 'korichnivye'
      },
      {
        title: 'Бежевый',
        value: 'beige',
        urlName: 'bezhevye'
      },
      {
        title: 'Рыжий',
        value: 'red',
        urlName: 'ryzhie'
      },
      {
        title: 'Песочный',
        value: 'sand',
        urlName: 'pesochnye'
      },
      {
        title: 'Белый',
        value: 'white',
        urlName: 'belye'
      },
      {
        title: 'Дымчатый',
        value: 'smoky',
        urlName: 'dymchatye'
      },
      {
        title: 'Трехцветный',
        value: 'tricolor',
        urlName: 'trehcvetnye'
      },
      {
        title: 'Двухцветный',
        value: 'twocolor',
        urlName: 'dvuhcvetnye'
      },
      {
        title: 'Мраморный',
        value: 'marble',
        urlName: 'mramornye'
      },
      {
        title: 'Тигровый',
        value: 'tiger',
        urlName: 'tigrovye'
      },
      {
        title: 'Чепрачный',
        value: 'black-backed',
        urlName: 'cheprachnye'
      },
      {
        title: 'Волчий',
        value: 'wolf',
        urlName: 'volchie'
      },
      {
        title: 'Зонарный',
        value: 'zonar',
        urlName: 'zonarnye'
      },
      {
        title: 'Подпалый',
        value: 'tan',
        urlName: 'podpalye'
      },
      {
        title: 'Подлас',
        value: 'podlas',
        urlName: 'podlas'
      },
      {
        title: 'Крап',
        value: 'krap',
        urlName: 'krap'
      },
      {
        title: 'Сплошной',
        value: 'solid',
        urlName: 'sploshnye'
      },
      {
        title: 'Черепаховый',
        value: 'tortoiseshell',
        urlName: 'cherepahovye'
      },
      {
        title: 'Голубо-кремовый',
        value: 'blue-cream',
        urlName: 'golubo-kremovye'
      },
      {
        title: 'Серебристый',
        value: 'silver',
        urlName: 'serebristye'
      },
      {
        title: 'Голубой',
        value: 'blue',
        urlName: 'golubye'
      },
      {
        title: 'Сиреневый',
        value: 'lilac',
        urlName: 'sirenevye'
      },
      {
        title: 'Красный',
        value: 'redtrue',
        urlName: 'krasnye'
      },
      {
        title: 'Лиловый',
        value: 'purple',
        urlName: 'lilovye'
      },
      {
        title: 'Палевый',
        value: 'pale-yellow',
        urlName: 'palevye'
      },
      {
        title: 'Золотой',
        value: 'gold',
        urlName: 'zolotye'
      }
    ]
  },
  castrationVariants: noMatterYesNoSearchVariants({
    title: 'Стерилизация/кастрация',
    filterName: 'pet_castration',
  }),
  parasiteVariants: noMatterYesNoSearchVariants({ 
    title: 'Обработка от паразитов',
    filterName: 'pet_parasite'
  }),
  vaccinationVariants: noMatterYesNoSearchVariants({
    title: 'Вакцинация',
    filterName: 'pet_vaccination'
  }),
  shippingVariants: noMatterYesNoSearchVariants({
    title: 'Ветеринарный паспорт',
    filterName: 'pet_passport'
  }),
  passportVariants: noMatterYesNoSearchVariants({
    title: 'Чипирование',
    filterName: 'pet_shipping'
  }),
  wcFriendlyVariants: noMatterYesNoSearchVariants({
    title: 'Приучен к лотку',
    filterName: 'pet_wc'
  }),
  walkingVariants: noMatterYesNoSearchVariants({
    title: 'Приучен к выгулу',
    filterName: 'pet_likes_walking'
  }),
  leashVariants: noMatterYesNoSearchVariants({
    title: 'Приучен к поводку',
    filterName: 'pet_leash'
  }),
  sharpenVariants: noMatterYesNoSearchVariants({
    title: 'Приучен к когтеточке',
    filterName: 'pet_claw_sharpen'
  }),
  commandsVariants: noMatterYesNoSearchVariants({
    title: 'Обучен командам',
    filterName: 'pet_commands'
  }),
  childFriendlyVariants: yesNoNeutralSearchVariants({
    title: 'Ладит с детьми',
    filterName: 'pet_child_friendly'
  }),
  catFriendlyVariants: yesNoNeutralSearchVariants({
    title: 'Ладит с кошками',
    filterName: 'pet_cat_friendly'
  }),
  dogFriendlyVariants: yesNoNeutralSearchVariants({ 
    title: 'Ладит с собаками',
    filterName: 'pet_dog_friendly'
  }),

  commonVariants: {
    variants: [
      {
        title: 'Любой',
        value: 'All'
      },
      {
        title: 'Нравится бегать',
        value: 'long_walk'
      },
      {
        title: 'Ладит с другими животными',
        value: 'other_pets'
      }
    ]
  },
  presetVariants: {
    filterName: 'owner_temp',
    variants: [
      {
        title: 'Спокойный',
        value: 'calm'
      },
      {
        title: 'Активный',
        value: 'active'
      }
    ]
  },
}
