import { put } from 'redux-saga/effects'
import { getPartnersAction } from '../actions/partners'
import { getEventsAction } from '../actions/events'

function* events() {
  yield put(getPartnersAction())
  yield put(getEventsAction())
}

export default events
