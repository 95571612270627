import { apiAction } from './apiAction'

export const GET_PET_CITIES_REQUEST = 'GET_PET_CITIES_REQUEST'
export const GET_PET_CITIES_SUCCESS = 'GET_PET_CITIES_SUCCESS'
export const GET_PET_CITIES_FAILURE = 'GET_PET_CITIES_FAILURE'

export const getPetCities = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/cities',
    method: 'GET',
    types: [
      GET_PET_CITIES_REQUEST,
      GET_PET_CITIES_SUCCESS,
      GET_PET_CITIES_FAILURE
    ],
    noAuthorization: true
  })
}
