import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, Route, Switch, withRouter } from 'react-router-dom'
import Popup from '../popup'
import PopupPager from '../popup/popupPager'
import Button from '../button'
import LoginForm from '../forms/loginForm'
import RegistrationForm from '../forms/registrationForm'
import RestoreForm from '../forms/restorePasswordForm'
import ReactComment from '../ReactComment/ReactComment'
import './style.scss'
import NavLink from 'react-router-dom/NavLink'
import { getCookie, markTargetAction } from '../../utils/getCookie'
import { 
  logoBtnClickEvent, loginAndRegistrationBtnClickEvent, 
  menuFindPetBtnClickEvent, menuAboutProjectBtnClickEvent, 
  menuQandABtnClickEvent, menuArticlesBtnClickEvent, mainPageAllStoriesBtnClickEvent } from "../../utils/ga4-events"

function NoIndex(props) {
  return (
    <span>
      <span dangerouslySetInnerHTML={{ __html: '<!--noindex-->' }} />
      {props.children}
      <span dangerouslySetInnerHTML={{ __html: '<!--/noindex-->' }} />
    </span>
  )
}

class Nav extends Component {
  static propTypes = {
    btnAction: PropTypes.func.isRequired,
    submitLoginAction: PropTypes.func.isRequired,
    registrationConfirm: PropTypes.func.isRequired,
    submitRegistrationAction: PropTypes.func.isRequired,
    submitRestoreAction: PropTypes.func.isRequired,
    restoreCompleteAction: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    toggleLoginPopup: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    isAuth: PropTypes.bool.isRequired,
    loginPopupIsOpened: PropTypes.bool.isRequired,
    loginPopupTab: PropTypes.string,
    isError: PropTypes.bool,
    isFinishedAnswersPopup: PropTypes.bool.isRequired,
    isOpenedAnswersPopup: PropTypes.bool.isRequired,
    countVisitPagePet: PropTypes.number.isRequired,
    openPetAnswerPopup: PropTypes.func.isRequired,
    closePetAnswerPopup: PropTypes.func.isRequired
  }

  state = {
    menuIsOpened: false,
    subMenuIsOpened: false,
    timer: null
  }

  toggleMenu() {
    this.setState({ menuIsOpened: !this.state.menuIsOpened })
  }

  closeMenu(value) {
    this.setState({ menuIsOpened: false })
    switch(value) {
      case "find": menuFindPetBtnClickEvent(); break;
      case "about": menuAboutProjectBtnClickEvent(); break;
      case "question": menuQandABtnClickEvent(); break;
      default: break;
    }
  }

  toggleLoginPopup() {
    const { toggleLoginPopup, loginPopupIsOpened } = this.props
    loginAndRegistrationBtnClickEvent()
    toggleLoginPopup(loginPopupIsOpened)
  }

  changePopupForms(formType) {
    const { toggleLoginPopup, loginPopupIsOpened } = this.props
    toggleLoginPopup(!loginPopupIsOpened, formType)
  }

  toggleSubMenu = () => {
    this.setState({ subMenuIsOpened: !this.state.subMenuIsOpened })
    menuArticlesBtnClickEvent()
  }

  // Таймер для бездействия на сайте в течении 45 секунд
  startTimerByOpenAnswersForm() {
    clearTimeout(this.state.timer)
  }

  closeWindowHandler(e) {
    let isFinishedAnswersPopup = getCookie('is_finished_answers_popup')
    isFinishedAnswersPopup = isFinishedAnswersPopup === null || +isFinishedAnswersPopup === 0 ? false : true

    let petPageCount = getCookie('pet_page_count')
    petPageCount = petPageCount == null ? 0 : parseInt(petPageCount, 10)

    if (!isFinishedAnswersPopup && petPageCount >= 1) {
      this.props.openPetAnswerPopup()
    }
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].onmouseleave = this.closeWindowHandler.bind(this)

    window.addEventListener('click', this.startTimerByOpenAnswersForm.bind(this), false)
    this.startTimerByOpenAnswersForm()
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].onmouseleave = null
    window.removeEventListener('click', this.startTimerByOpenAnswersForm.bind(this), false)
  }

  componentDidUpdate() {
    let petPageCount = getCookie('pet_page_count')
    petPageCount = petPageCount == null ? 0 : parseInt(petPageCount, 10)

    let isFinishedAnswersPopup = getCookie('is_finished_answers_popup')
    isFinishedAnswersPopup = isFinishedAnswersPopup === null || +isFinishedAnswersPopup === 0 ? false : true

    let didTargetAction = getCookie('did_target_action')
    didTargetAction = didTargetAction === null || +didTargetAction === 0 ? false : true

    if (this.props.location.pathname.indexOf('/pets') === -1 &&
      this.props.location.pathname.indexOf('/pet/') === -1 &&
      petPageCount >= 2 &&
      !isFinishedAnswersPopup &&
      !didTargetAction
    ) {
      this.props.openPetAnswerPopup()
    }
  }

  render() {
    const {
      submitRegistrationAction,
      submitLoginAction,
      registrationConfirm,
      submitRestoreAction,
      isError,
      isAuth,
      user,
      loginPopupTab,
      restoreCompleteAction,
    } = this.props

    const pages = [
      {
        title: 'Войти',
        action: this.changePopupForms.bind(this, 'login'),
        current: loginPopupTab === 'login'
      },
      {
        title: 'Регистрация',
        action: this.changePopupForms.bind(this, 'registration'),
        current: loginPopupTab === 'registration'
      },
      {
        title: 'Забыли пароль?',
        action: this.changePopupForms.bind(this, 'restorePassword'),
        current: loginPopupTab === 'restorePassword'
      }
    ]
    const pathname = this.props.location.pathname

    const heart = (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <path
          fill='#ff0000'
          d='M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z'
        />
      </svg>
    )

    const isLightClass =
      pathname === '/' ||
      pathname.indexOf('fonds') !== -1 ||
      pathname.indexOf('shop') !== -1 ||
      pathname.indexOf('vet') !== -1 ||
      pathname.indexOf('library') !== -1 ||
      pathname.indexOf('pets/') !== -1 ||
      pathname.indexOf('confirm') !== -1 ||
      pathname.indexOf('restore') !== -1 ||
      pathname.indexOf('partners') !== -1 ||
      pathname.indexOf('articles/') !== -1 ||
      pathname.indexOf('our-articles/') !== -1 ||
      pathname.indexOf('favorites/') !== -1 ||
      pathname === '/404' ||
      pathname === '/cats' ||
      pathname === '/dogs' ||
      pathname === '/pets' ||
      pathname === '/pets-owners' ||
      pathname === '/school' ||
      pathname === '/club' ||
      pathname === '/about' ||
      pathname === '/stories' ||
      pathname === '/FAQ' ||
      pathname === '/products' ||
      pathname === '/volunteering' ||
      pathname === '/restore' ||
      pathname === '/feedback' ||
      pathname === '/articles' ||
      pathname === '/our-articles' ||
      pathname === '/favorites' ||
      pathname === '/pet-search-sitemap' ||
      pathname === '/events'

    return (
      <div>
        <nav
          className={isError ? 'nav' : 'nav ' + (isLightClass ? 'light' : '')}
        >
          <Switch>
            <Route
              path='/pet'
              component={() => (
                <Link className='nav__logo' to={'/'} onClick={()=> logoBtnClickEvent()}>
                  <img src={'/images/navLogo2.svg'} alt='logo' />
                </Link>
              )}
            />
            <Route
              path='/lk'
              component={() => (
                <Link className='nav__logo' to={'/'} onClick={()=> logoBtnClickEvent()}>
                  <img src={'/images/navLogo2.svg'} alt='logo' />
                </Link>
              )}
            />
            <Route
              path='/'
              exact
              component={() => (
                <span className='nav__logo'>
                  <img src={'/images/navLogo.svg'} alt='Логотип FriendForPet' />
                </span>
              )}
              onClick={()=> logoBtnClickEvent()}
            />
            <Route
              path='*'
              component={() =>
                (pathname.split('/')[pathname.split('/').length - 1] === '404.html' || (pathname === '/404') || isError) ? (
                  <Link className='nav__logo' to={'/'} onClick={()=> logoBtnClickEvent()}>
                    <img src={'/images/navLogo2.svg'} alt='logo' />
                  </Link>
                ) : (
                  <Link className='nav__logo' to={'/'} onClick={()=> logoBtnClickEvent()}>
                    <img src={'/images/navLogo.svg'} alt='logo' />
                  </Link>
                )
              }
            />
          </Switch>
          <div
            className={'nav__menu ' + (this.state.menuIsOpened ? 'opened' : '')}
          >
            <div className='nav__menu-wrapper'>
              <Link
                className={
                  'menu__item ' +
                  (pathname.indexOf('pets') !== -1 ? 'current' : '')
                }
                to={'/pets'}
                onClick={this.closeMenu.bind(this, "find")}
              >
                <span ref={pathname.indexOf('pets') !== -1 ? 'current' : ''}>
                  Найти питомца
                </span>
              </Link>

              <Link
                className={
                  'menu__item ' +
                  (pathname.indexOf('about') !== -1 ? 'current' : '')
                }
                to={'/about'}
                onClick={this.closeMenu.bind(this, "about")}
              >
                <span ref={pathname.indexOf('about') !== -1 ? 'current' : ''}>
                  О проекте
                </span>
              </Link>

              <Link
                className={
                  'menu__item ' +
                  (pathname.indexOf('FAQ') !== -1 ? 'current' : '')
                }
                to={'/FAQ'}
                onClick={this.closeMenu.bind(this, "question")}
              >
                <span ref={pathname.indexOf('FAQ') !== -1 ? 'current' : ''}>
                  Вопросы и ответы
                </span>
              </Link>

              {/*
              <Link
                className={
                  'menu__item ' +
                  (pathname.indexOf('volunteering') !== -1 ? 'current' : '')
                }
                to={'/volunteering'}
                onClick={this.closeMenu.bind(this)}
              >
                <span
                  ref={pathname.indexOf('volunteering') !== -1 ? 'current' : ''}
                >
                  Помочь приютам
                </span>
              </Link>
              */}

              <div
                className={`menu__item menu__item--withSubMenu ${
                  this.state.subMenuIsOpened ? 'open' : ''
                }`}
                onClick={this.toggleSubMenu}
              >
                <div className='menu__itemTitle'>Статьи</div>
                <div className='submenu'>
                  <NavLink
                    className='submenu__item'
                    activeClassName='submenu__item--active'
                    to='/our-articles'
                    onClick={this.closeMenu.bind(this)}
                  >
                    Полезные статьи
                  </NavLink>
                  <NavLink
                    className='submenu__item'
                    activeClassName='submenu__item--active'
                    to='/school'
                    onClick={this.closeMenu.bind(this)}
                  >
                    Школа ответственных хозяев
                  </NavLink>
                </div>
              </div>

              {isAuth ? (
                <Link
                  className={
                    'menu__item favorites ' +
                    (pathname.indexOf('favorites') !== -1 ? 'current' : '')
                  }
                  onClick={this.closeMenu.bind(this)}
                  to={'/favorites'}
                >
                  <span className={'heart-icon'}>{heart}</span>
                  <span
                    ref={pathname.indexOf('favorites') !== -1 ? 'current' : ''}
                  >
                    Избранное
                  </span>
                </Link>
              ) : null}

              <div className='nav__line' ref='navPager' />
            </div>
          </div>
          <NoIndex>
            <div className='nav__btn-wrapper mobile-menu'>
              {isAuth ? (
                <Link
                  key='icon'
                  className='person-icon'
                  to={'/lk'}
                  onClick={() => {
                    this.closeMenu()
                    markTargetAction()
                  }}
                />
              ) : (
                <Button
                  handleClick={this.toggleLoginPopup.bind(this)}
                  text='ВХОД И РЕГИСТРАЦИЯ'
                  iconSrc='/images/person-icon.svg'
                  additionalClass='person-btn'
                />
              )}
              <Button
                handleClick={this.toggleMenu.bind(this)}
                text='МЕНЮ'
                iconSrc='/images/burger-icon.png'
                additionalClass='menu-btn'
              >
                <div
                  className={
                    'icon icon-burger ' +
                    (this.state.menuIsOpened ? 'opened' : '')
                  }
                />
              </Button>
              {isAuth && (
                <Link
                  className={
                    'menu__item favorites-mobile ' +
                    (pathname.indexOf('favorites') !== -1 ? 'current' : '')
                  }
                  onClick={this.closeMenu.bind(this)}
                  to={'/favorites'}
                >
                  <span className={'heart-icon'}>{heart}</span>
                </Link>
              )}
            </div>
          </NoIndex>
        </nav>

        <Popup
          opened={this.props.loginPopupIsOpened}
          closeBtnFunc={this.toggleLoginPopup.bind(this)}
        >
          <PopupPager pages={pages} />
          {loginPopupTab === 'login' ? (
            <div>
              <ReactComment text={`noindex`} />
              <LoginForm
                active={!(user && user.loginRequest)}
                submitAction={submitLoginAction}
                registrationConfirm={registrationConfirm}
                linkAction={this.changePopupForms.bind(this, 'restorePassword')}
              />
              <ReactComment text={`/noindex`} />
            </div>
          ) : (
            undefined
          )}
          {loginPopupTab === 'registration' ? (
            <div>
              <ReactComment text={`noindex`} />
              <RegistrationForm
                active={!(user && user.registrationRequest)}
                submitAction={submitRegistrationAction}
                registrationConfirm={registrationConfirm}
              />
              <ReactComment text={`/noindex`} />
            </div>
          ) : (
            undefined
          )}
          {loginPopupTab === 'restorePassword' ? (
            <div>
              <ReactComment text={`noindex`} />
              <RestoreForm
                active={!(user && user.restoreRequest)}
                restoreNextStep={user && user.restoreNextStep}
                submitAction={submitRestoreAction}
                restoreCompleteAction={restoreCompleteAction}
              />
              <ReactComment text={`/noindex`} />
            </div>
          ) : (
            undefined
          )}
        </Popup>

        {/*{!isFinishedAnswersPopup && <Popup
          opened={isOpenedAnswersPopup}
          closeBtnFunc={closePetAnswerPopup}
        >
          <AnswersForm />
        </Popup>}*/}
      </div>
    )
  }
}

export default withRouter(Nav)
