import { put, race, select, take } from 'redux-saga/effects'
import queryString from 'query-string'
import { getMeAction, LOGIN_SUCCESS, RESTORE_COMPLETE_FAILURE, RESTORE_COMPLETE_SUCCESS } from '../actions/user'

import { sendCuratorRequest } from '../actions/curator'

import { needSendPetAction, toggleLoginPopup } from '../actions'

import { loginWebStat } from '../utils/weboramaAction'

export const needSendAction = state => state.loginPopup.needSendPetAction
export const currentPet = state => state.pets.currentPet.nid[0].value
export const userToken = state => state.user.token

function* loginSaga(history) {
  try {
    const pathname = queryString.parse(window.location.pathname)
    const parsed = queryString.parse(window.location.search)
    if (Object.keys(pathname)[0] === '/restore' && parsed && parsed.hash) {
      yield put(toggleLoginPopup(false, 'restorePassword'))
      const { success, failure } = yield race({
        success: take(RESTORE_COMPLETE_SUCCESS),
        failure: take(RESTORE_COMPLETE_FAILURE)
      })
      if (success) {
        history.push('/')
        yield put(toggleLoginPopup(true, 'restore'))
      } else if (failure) {
        history.push('/')
        yield put(toggleLoginPopup(true, 'restore'))
      }
    }
    let usToken = yield select(userToken)

    if (usToken) {
      yield put(getMeAction())
    }

    while (true) {
      yield take(LOGIN_SUCCESS)
      let sendAction = yield select(needSendAction)
      yield loginWebStat()
      yield put(toggleLoginPopup(true, 'login'))
      yield put(getMeAction())
      if (sendAction) {
        let petId = yield select(currentPet)
        yield put(
          sendCuratorRequest({
            pet_id: petId
          })
        )
        yield put(needSendPetAction(false))
      }
    }
  } catch (e) {
    yield null
  }
}

export default loginSaga
