import {
  GET_ARTICLESLIST_FAILURE,
  GET_ARTICLESLIST_REQUEST,
  GET_ARTICLESLIST_SUCCESS,
  GET_CURRENT_ARTICLE_FAILURE,
  GET_CURRENT_ARTICLE_REQUEST,
  GET_CURRENT_ARTICLE_SUCCESS,
  GET_RANDOM_ARTICLES_SUCCESS,
  SET_SEARCH_ARTICLE_TITLE,
  GET_CURRENT_ARTICLE_SIMILAR_SUCCESS
} from '../actions/articles'

const initialState = {
  list: [],
  filterResult: [],
  currentArticle: {},
  currentArticleSimilar: {},
  success: false,
  error: false,
  title: null,
  randomArticles: []
}

export const articles = (state = initialState, action) => {
  const { type, payload, meta } = action

  switch (type) {
    case GET_ARTICLESLIST_REQUEST:
    case GET_CURRENT_ARTICLE_REQUEST:
      return {
        ...state,
        success: false,
        error: false
      }
    case GET_ARTICLESLIST_SUCCESS:
      let result = {}
      if (meta.searching) {
        result = {
          ...state,
          filterResult: payload,
          success: true
        }
      } else {
        result = {
          ...state,
          list: payload,
          success: true
        }
      }
      return result
    case GET_RANDOM_ARTICLES_SUCCESS:
      return {
        ...state,
        randomArticles: payload?.results?.length
          ? payload.results.slice(-3)
          : []
      }
    case GET_CURRENT_ARTICLE_SUCCESS:
      const isError = !payload.length
      return {
        ...state,
        success: !isError,
        error: isError,
        currentArticle: isError ? payload : payload[0]
      }
    case SET_SEARCH_ARTICLE_TITLE:
      return {
        ...state,
        title: meta.value
      }
    case GET_ARTICLESLIST_FAILURE:
    case GET_CURRENT_ARTICLE_FAILURE:
      return { ...state, error: true }
    case GET_CURRENT_ARTICLE_SIMILAR_SUCCESS:
      return {
        ...state,
        success: true,
        currentArticleSimilar: payload?.results?.length
          ? payload.results.slice(-3)
          : []
      }
    default:
      return state
  }
}
