import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.scss'

function NoIndex(props) {
  return (
    <span>
      <span dangerouslySetInnerHTML={{ __html: '<!--noindex-->' }} />
      {props.children}
      <span dangerouslySetInnerHTML={{ __html: '<!--/noindex-->' }} />
    </span>
  )
}

class Popup extends Component {
  static propTypes = {
    opened: PropTypes.bool.isRequired,
    closeBtnFunc: PropTypes.func.isRequired,
    type: PropTypes.string
  }

  state = {
    menuIsOpened: false
  }

  render() {
    const {
      opened = false, closeBtnFunc = () => {
      }, type = ''
    } = this.props
    // const style = { marginTop: opened ? window.pageYOffset + 20 + 'px' : '0px' }

    return (
      <NoIndex>
        <div className={'custom-popup ' + (opened ? 'opened ' : ' ') + type}>
          <div className='custom-popup__wrapper'>
            <div className='custom-popup__close-btn' onClick={closeBtnFunc} />
            {this.props.children}
          </div>
        </div>
      </NoIndex>
    )
  }
}

export default Popup
