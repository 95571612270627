import validator from 'validator/lib/isEmail'
import passwordValidator from 'password-validator'

const hasDigits = new passwordValidator()
hasDigits.has().digits()

const hasLetters = new passwordValidator()
hasLetters.has().letters()

const hasUppercase = new passwordValidator()
hasUppercase.has().uppercase()

const hasLowercase = new passwordValidator()
hasLowercase.has().lowercase()

const hasCyrillic = new passwordValidator()
hasCyrillic.not(/[а-яА-ЯёЁ]+/)

export const required = value => (value ? undefined : 'Необходимо заполнить')

export const mustBeNumber = value =>
  isNaN(value) ? 'Должно быть числом' : undefined

export const email = (value = '') =>
  validator(value) ? undefined : 'Введите email'

export const masked = (value = '') =>
  value.indexOf('_') === -1 ? undefined : 'Завершите ввод'

export const password = (value = '') => {
  if (value && value.length < 8) {
    return 'Меньше 8 символов'
  }

  if (value && value.length > 100) {
    return 'Больше 100 символов'
  }

  if (value && !hasDigits.validate(value)) {
    return 'Не содержит цифры'
  }

  if (value && !hasCyrillic.validate(value)) {
    return 'Только латиница'
  }

  if (value && !hasLetters.validate(value)) {
    return 'Не содержит буквы'
  }

  if (value && !hasUppercase.validate(value)) {
    return 'Минимум одна буква верхнего регистра'
  }

  if (value && !hasLowercase.validate(value)) {
    return 'Минимум одна буква нижнего регистра'
  }

  return undefined
}

export const confirmPassword = (password1 = '', password2 = '') => {
  return password1 === password2 ? undefined : 'Пароли не совпадают'
}

export const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Должно быть больше чем  ${min}`

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
