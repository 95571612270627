import { apiAction } from './apiAction'

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST'
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS'
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE'

export const getEventsAction = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/events',
    method: 'GET',
    types: [GET_EVENTS_REQUEST, GET_EVENTS_SUCCESS, GET_EVENTS_FAILURE],
    noAuthorization: true
  })
}

export const SHOW_EVENT_IN_POPUP = 'SHOW_EVENT_IN_POPUP'

export const showEventInPopup = id => ({
  type: SHOW_EVENT_IN_POPUP,
  payload: {
    currentEvent: id,
    isEventsPopupOpened: true
  }
})

export const CLOSE_EVENTS_POPUP = 'CLOSE_EVENTS_POPUP'

export const closeEventsPopup = id => ({
  type: CLOSE_EVENTS_POPUP,
  payload: {
    isEventsPopupOpened: false
  }
})
