import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Wrapper from '../wrapper'
import './style.scss'

class Header extends Component {
  static propTypes = {
    userName: PropTypes.string
  }

  render() {
    return (
      <header className='header'>
        {/*
        <Wrapper>
          <a
            rel='noreferrer noopener nofollow'
            className='header__logo'
            href='https://www.friendforpet.ru/'
            target='_blank'
          >
            <img
              src='/images/logo.svg'
              alt='Логотип Purina'
              width='269'
              height='16'
            />
          </a>
        </Wrapper>
        */}
      </header>
    )
  }
}

export default Header
