import { apiAction } from './apiAction'

export const GET_TRAINING_INFO_REQUEST = 'GET_TRAINING_INFO_REQUEST'
export const GET_TRAINING_INFO_SUCCESS = 'GET_TRAINING_INFO_SUCCESS'
export const GET_TRAINING_INFO_FAILURE = 'GET_TRAINING_INFO_FAILURE'

export const getTrainingInfoAction = (type = 'med') => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/learning/page/?type=' + type,
    method: 'GET',
    types: [
      GET_TRAINING_INFO_REQUEST,
      GET_TRAINING_INFO_SUCCESS,
      GET_TRAINING_INFO_FAILURE
    ],
    noAuthorization: true
  })
}
