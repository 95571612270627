import { apiAction } from './apiAction'

export const GET_FAQ_REQUEST = 'GET_FAQ_REQUEST'
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS'
export const GET_FAQ_FAILURE = 'GET_FAQ_FAILURE'

export const getFaqAction = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/faq',
    method: 'GET',
    types: [GET_FAQ_REQUEST, GET_FAQ_SUCCESS, GET_FAQ_FAILURE],
    noAuthorization: true
  })
}
