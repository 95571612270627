import { put, race, take } from 'redux-saga/effects'
import { EDIT_ME_SUCCESS, getMeAction, getMyPetsAction, REMOVE_MY_PET_SUCCESS } from '../actions/user'

function* lkSaga(history) {
  yield put(getMyPetsAction())
  while (true) {
    const { edit, removePet } = yield race({
      edit: take(EDIT_ME_SUCCESS),
      removePet: take(REMOVE_MY_PET_SUCCESS)
    })
    if (edit) {
      yield put(getMeAction())
    }
    if (removePet) {
      yield put(getMeAction())
      yield put(getMyPetsAction())
    }
  }
}

export default lkSaga
