import {
  GET_ARTICLES_SUCCESS,
  GET_MATERIAL_SHORT_REQUEST,
  GET_MATERIAL_SHORT_SUCCESS,
  SET_CURRENT_ARTICLE
} from '../actions/library'

const ARTICLES = [
  {
    groupId: '1',
    groupTitle: 'Ответственность',
    articles: [
      {
        id: '53',
        title: 'Прогулки с собаками из приюта',
        metatitle:
          'Прогулки с собаками из приюта 🐕- FriendForPet',
        desc:
          'Для собаки, которую вы взяли из приюта домой, прогулки имеют огромное значение.',
        metadesc:
          'Как гулять с собакой из приюта: сколько раз в день выгуливать, как долго, почему важны прогулки с собаками из приюта, читайте на сайте проекта Purina «Друг для друга».',

        important: {
          text:
            'Важной частью социальной адаптации являются совместные прогулки. Поэтому необходимо выгуливать собак, взятых из приюта, как можно чаще и дольше, постепенно увеличивая количество новых мест для прогулок.'
        },
        content: [
          {
            img: '/images/articles/2/shutterstock_279548804_225.jpg',
            text:
              'Животные в приюте имеют разный социальный опыт. Одни питомцы знают, что такое общественный транспорт, у них был опыт общения с детьми и другими животными. У других никогда не было хозяина, и большую часть жизни они провели в социальной изоляции.\n\nЭтих собак пугает все новое, с чем они сталкиваются – стук дверей, крики соседей, голуби, шумно взлетевшие в сквере. Для вашего питомца это может стать источником стресса.'
          },
          {
            img: '/images/articles/2/shutterstock_12452413_225.jpg',
            text:
              'Важной частью социальной адаптации являются совместные прогулки. Поэтому необходимо выгуливать собак, взятых из приюта, как можно чаще и дольше, постепенно увеличивая количество новых мест для прогулок.\n\nНа улице ваш питомец получит новый опыт взаимодействия с миром, находясь рядом с вами, в спокойной, безопасной обстановке. Сможет понаблюдать за летящей птичкой, познакомится и поиграть с другой собакой. Вы можете заранее договориться с соседями - владельцами собак, чтобы они выходили на прогулку вместе с вами и вашим новым питомцем.\n\nПрогулки играют важную роль в развитии и укреплении нервной системы, физического состояния животного, а также приучают их к чистоплотности.\n\nВажно следовать режиму прогулок: первый раз – сразу после пробуждения, второй раз – в обед, после еды, и третий раз – перед сном. При этом, чем чаще вы будете менять маршрут, тем больше новых и важных знаний получит ваш новый друг.'
          },
          {
            img: '/images/articles/2/shutterstock_218805265_225.jpg',
            text:
              'Помните, что минимальное время прогулки с любым взрослым животным – три раза в день по 30 минут. Желательно, чтобы во время прогулки собака получала достаточную физическую нагрузку.\n\nВ прогулках также важно поведение хозяина. Если он заботлив, спокоен, ведет себя уверенно, поощряет хорошее поведение и правильное выполнение команд, то собака очень быстро начнет ему доверять, адаптироваться к новой окружающей среде, другим людям и животным.\n\nНесмотря на сложности в воспитании животного, когда вы берете питомца через проект Purina® «Друг для друга», вы заручаетесь поддержкой опытных кинологов, зоопсихологов и ветеринаров, а также экспертов горячей линии, которые всегда готовы ответить на ваши вопросы.'
          }
        ]
      },
      {
        id: '52',
        title: 'Животные и дети',
        metatitle:
          'Взаимоотношения детей и животных из приюта',
        desc:
          'Что делать, если в семью, в которой уже есть дети, попадают животные?',
        metadesc:
          'Узнайте, как научить маленьких детей бережному и ответственному обращению с домашними животными, как подготовить ребенка к появлению собаки или кошки в доме.',

        important: {
          text:
            'Лучший способ узнать, как будут складываться отношения у вашего ребенка и будущего питомца – поехать в приют с ребенком заранее, чтобы животное и ребенок могли познакомиться и подружиться.'
        },
        content: [
          {
            img: '/images/articles/3/shutterstock_250656241_225.jpg',
            text:
              'Любые родители хотят, чтобы домашние любимцы стали друзьями для их детей, а чтобы это случилось как можно скорее, нужно подойти к этому вопросу максимально ответственно.\n\nПеред тем, как питомец окажется в квартире, корректно, но твердо родители должны объяснить детям, что животные – это не живые мягкие игрушки, а совершенно другой биологический вид. Более того, у них есть зубы и когти, они не умеют разговаривать и очень плохо понимают человеческую речь.\n\nПоэтому родителям необходимо научить детей бережному и ответственному обращению с животными, а первые три недели и более, общение ребенка и нового члена семьи должно проходить под наблюдением взрослых.'
          },
          {
            img: '/images/articles/3/shutterstock_745011472_225.jpg',
            text:
              'Идеальным будет, если и родители и их вновь приобретенный питомец пройдут беби-классы по социальной адаптации и дрессировке щенков и котят. Это позволит родителям и детям чувствовать себя гораздо уверенней в общении с новыми членами их семьи.\n\nДеткам нужно будет объяснить, что миска для еды кошки или собаки – это неприкосновенная территория. Оттуда ест только питомец, туда нельзя ничего подкладывать – ни съедобного, ни несъедобного. Также запрещается подкармливать животное со стола во время семейной трапезы. А миска для воды у нового члена семьи всегда должна быть наполнена чистой водой.'
          },
          {
            img: '/images/articles/3/shutterstock_762193828_225.jpg',
            text:
              'Все, что могут делать дети под контролем взрослых – это поощрять животных лакомством за правильно выполненные команды или за хорошее поведение. И тогда, при правильном воспитании животных и корректном с ними обращении, любой питомец со временем станет лучшим другом ребенка и отличным товарищем для игр.\n\nОдним из обязательств бренда Purina® является популяризация образовательных программ для детей, посвященных ответственному отношению к домашним животным.'
          }
        ]
      }
    ]
  },
  {
    groupId: '2',
    groupTitle: 'Социализация',
    articles: [
      {
        id: '95',
        title: 'Переезд в новый дом',
        metatitle:
          'Адаптация животных из приюта к новому дому',
        metadesc:
          'Узнайте больше о адаптации кошек и собак к новому дому после приюта. О том, как приучить котенка или щенка к квартире, читайте на сайте проекта Purina «Друг для друга».',
        desc:
          'Узнайте больше о адаптации кошек и собак к новому дому после приюта. О том, как приучить котенка или щенка к квартире, читайте на сайте проекта Purina «Друг для друга».',

        important: {
          text:
            'Если вы готовы взять из приюта кошку, то в первую очередь убедитесь, что животное клинически здорово, обработано от глистов и паразитов, и у него есть все необходимые прививки.'
        },
        content: [
          {
            img: '/images/articles/1/shutterstock_668579824_225.jpg',
            text:
              'Дело в том, что собаки, попавшие из приюта в городские квартиры, очень часто ведут себя достаточно шумно. Они могут выть, оставшись в одиночестве, копать под дверь, запрыгивать на окна, разбивать горшки с цветами, облаивать проходящих мимо соседей – и все это может длиться часами.\n\nВторой момент, к которому нужно быть готовым, – это возможное нечистоплотное поведение животного, даже если вы выгуливаете питомца трижды в день. К этому можно подготовиться заранее, закрыв двери во все помещения, а в комнате, где живет собака, застелить свободные места впитывающими пеленками.\n\nПри этом животное ни в коем случае не стоит ругать за пробелы в воспитании и пытаться сразу побороть его вредные привычки. Продолжайте выгуливать его трижды в день и поощряйте лаской и лакомством за каждое правильно выбранное действие.'
          },
          {
            img: '/images/articles/1/shutterstock_795301972_225.jpg',
            text:
              'Затем осмотрите свою квартиру и подумайте о том, будет ли вашей кошке здесь комфортно. Лучше всего, если у нее будет домик-когтеточка, полки, по которым она сможет прыгать и лазить. Устройте для питомца несколько кормушек с лакомствами в разных местах квартиры, которые она сможет искать в ваше отсутствие.\n\nЕсли у вас есть цветы, избавьтесь от тех, которые могут быть ядовиты для кошек. Также спрячьте в недоступное для животного место бытовую химию и уберите провода в специальные короба.\n\nПредложите своей кошке два лотка с разными наполнителями на выбор. Это поможет вам избавить себя от проблем с нечистоплотным поведением.\n\nНе забывайте о различных мячиках, мышках и удочках-дразнилках, которые разнообразят досуг вашего питомца.'
          },
          {
            img: '/images/articles/1/shutterstock_771705928_225.jpg',
            text:
              'Стоит помнить, что животное из приюта может нести груз различных психологических травм из прошлого. Они могут проявить себя спустя какое-то время даже в благополучной семье. В такой ситуации важно набраться терпения – продолжать проявлять ласку и любовь, корректировать поведение питомца и создавать необходимые условия для скорейшей адаптации на новом месте.\n\nНе забывайте, вы всегда можете обратиться за советом к ветеринарному врачу. А если вы берете питомца из приюта через проект Purina® «Друг для друга», то вы автоматически вступаете в клуб «Друзья на всю жизнь», где всегда получите консультацию куратора приюта, который передал вам питомца, а также советы специалистов по поведению, питанию и адаптации питомца. Просто позвоните по телефону Горячей линии: 8 (800) 200 8 900.'
          }
        ]
      }
    ]
  },
  {
    groupId: '3',
    groupTitle: 'Ветеринарная помощь',
    articles: [
      {
        id: '47036',
        title: 'Ветеринарная помощь',
        metatitle:
            'Ветеринарная помощь',
        metadesc:
            'Узнайте больше о ветеринарной помощи для кошек и собак',
        desc:
            'Иногда нашему мохнатому другу требуется оперативная ветеринарная помощь.',
        important: {
          text:
              'Горячая линия 8 800 200 8 900'
        },
        content: [
          {
            img: '/images/articles/1/photo_2022-10-24_16-09-45.jpg',
            text:
                '<p>Мы рекомендуем обращаться на Линию Заботы:</p>' +
                '<ul>' +
                '<li>Поддержка по всем видам связи</li>' +
                '<li>Профессиональные ветеринары</li>' +
                '<li>Срочная помощь 24\\7, в выходные и праздники</li>' +
                '<li>Индивидуальный подход</li>' +
                '</ul>' +
                '<p>Сайт: <a href="https://careline.purina.ru/">careline.purina.ru</a><br/>' +
                'WhatsApp: <a href="https://api.whatsapp.com/send/?phone=79160001070&text&type=phone_number&app_absent=">79160001070</a><br/>' +
                'Telegram: <a href="https://t.me/purina_careline_bot">@purina_careline_bot</a></p>'
          }
        ]
      }
    ]
  }
]

const initialState = {
  materialsShort: [],
  materialsShortPager: {},
  articles: ARTICLES,
  currentArticle: null
}

export const library = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case '@@router/LOCATION_CHANGE':
    case GET_MATERIAL_SHORT_REQUEST:
      return { ...state, materialsShort: [], materialsShortPager: {} }
    case GET_MATERIAL_SHORT_SUCCESS:
      return {
        ...state,
        materialsShort: action.payload.results,
        materialsShortPager: action.payload.pager
      }
    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload
      }
    case SET_CURRENT_ARTICLE:
      return {
        ...state,
        currentArticle: action.payload.currentArticle
      }
    default:
      return state
  }
}
