import React, { Component } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Popup from '../../popup'
import { Scrollbars } from 'react-custom-scrollbars'
import objectToFormData from 'object-to-formdata'
import FormInput from '../formComponents/input'
import { loadCities } from '../../../actions/user'
import { signUpClickEvent } from "../../../utils/ga4-events"

import Recaptcha from 'react-recaptcha'

import { confirmPassword, email, masked, password, required } from '../validationRules'
import Button from '../../button'
import '../style.scss'
import './style.scss'

class RegistrationForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    registrationConfirm: PropTypes.func.isRequired,
    active: PropTypes.bool
  }

  state = {
    personalPopupIsOpened: false,
    selectedOption: null,
    recaptcha: null
  }

  submitFormHandler() {
    const { submitAction, registrationConfirm } = this.props
    const sendingFormValues = {
      ...this.state.submittedValues,
      invite: window.localStorage.getItem('invite'),
      'g-recaptcha-response': this.state.recaptcha
      //region: this.state.selectedOption.RussianName
    }
    const formData = objectToFormData(sendingFormValues)
    const sendingValues = {
      username: this.state.submittedValues.phone,
      password: this.state.submittedValues.password1
    }
    registrationConfirm(this.state.submittedValues ? { ...sendingValues } : '')
    submitAction(formData)
    signUpClickEvent();
  }

  errorValidator = values => {
    return {
      firstname: required(values.firstname),
      lastname: required(values.lastname),
      policy_permission: required(values.policy_permission),
      process_data_permission: required(values.process_data_permission),
      region: required(values.region),
      email: email(values.email),
      password1: password(values.password1),
      password2: confirmPassword(values.password2, values.password1)
    }
  }

  togglePersonalPopup() {
    this.setState({ personalPopupIsOpened: !this.state.personalPopupIsOpened })
  }

  selectCity = selectedOption => {
    this.setState({ selectedOption })
  }

  componentDidMount() {
    this.props.loadCities()
  }

  verifyCallback = response => {
    const responseCaptcha = response
    let promise = new Promise((resolve, reject) => {
      if (responseCaptcha) {
        resolve('result')
      }
    })
    promise.then(result => {
      this.setState({
        recaptcha: responseCaptcha
      })
    })
  }
  callback = () => {
  }

  render() {
    const { active = true } = this.props
    return (
      <div>
        <div
          className={
            'form registration-form ' + (active ? 'active' : 'inactive')
          }
        >
          <span className='form-title'>Регистрация</span>
          <Form
            onSubmit={submittedValues => {
              this.setState(
                { submittedValues },
                this.submitFormHandler.bind(this)
              )
            }}
            validate={values => {
              const errors = {}
              if (
                values.password1 &&
                values.password2 &&
                confirmPassword(values.password1, values.password2)
              ) {
                errors.password2 = confirmPassword(
                  values.password1,
                  values.password2
                )
              }
              return Object.keys(errors).length ? errors : undefined
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit} autoComplete='off'>
                <div className='form__field form__field_m-50'>
                  <FormInput
                    name={'firstname'}
                    placeholder={'Имя*'}
                    rules={required}
                  />
                </div>
                {/* <div className="form__field form__field_m-50">
                  <FormInput
                    name={'lastname'}
                    placeholder={'Фамилия*'}
                    rules={required}
                  />
                </div> */}
                {/* <div className="form__field form__field_m-50">
                  <FormInput name={'city'} placeholder={'Город'} />
                </div> */}
                <div className='form__field form__field_m-50'>
                  <FormInput
                    name={'phone'}
                    placeholder={'Телефон*'}
                    mask='+79999999999'
                    rules={[required, masked]}
                  />
                </div>
                {/*<div className="form__field form__field_m-50">
                  <FormInput
                    name={'email'}
                    placeholder={'Email*'}
                    rules={[required, email]}
                  />
                </div>*/}
                <div className='form__field form__field_m-50'>
                  <FormInput
                    name={'password1'}
                    placeholder={'Пароль*'}
                    type='password'
                    rules={[required, password]}
                    tooltips={[
                      '8-символов',
                      'только латиница',
                      'минимум одна цифра',
                      'минимум одна буква верхнего регистра',
                      'минимум одна буква нижнего регистра'
                    ]}
                  />
                </div>
                {/*<div className="form__field form__field_m-50">
                  <FormSelect
                    name={'region'}
                    placeholder={'Регион'}
                    options={this.props.cities ? this.props.cities : []}
                    handleChange={e => this.selectCity(e)}
                    changeLabel={true}
                    value={
                      this.state.selectedOption
                        ? this.state.selectedOption
                        : null
                    }
                    rules={required}
                  />
                </div>*/}
                <div className='form__field form__field_m-50'>
                  <FormInput
                    name={'password2'}
                    placeholder={'Повторите пароль*'}
                    type='password'
                    rules={[required, password]}
                  />
                </div>
                <div className='form__field form__field_m-50'>
                  <Recaptcha
                    sitekey='6LdnRrkUAAAAAAVzPzecc7ax_rryXwZ7uAzG2VYs'
                    hl={'ru'}
                    verifyCallback={this.verifyCallback}
                    onloadCallback={() => this.callback()}
                  />
                </div>
                <div className='form__field form__field-checkbox'>
                  <FormInput
                    name={'process_data_permission'}
                    placeholder={''}
                    label={
                      <div className='input__label'>
                        Отмечая «Согласен» в чек-боксе, я подтверждаю,
                        что мне исполнилось 18 лет и даю согласие на обработку
                        моих персональных данных ООО «Нестле Россия»
                        в соответствие с политикой об обработке данных.
                        Подробнее о{' '}
                        <a
                            className="input__link"
                            href="https://www.friendforpet.ru/personal-data-agreement"
                            target="_blank"
                            rel="noopener noreferrer"
                        >согласии</a>{' '}
                        и{' '}
                        <a
                            className="input__link"
                            href="https://www.purina.ru/data-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >политике</a>.
                      </div>
                    }
                    type='checkbox'
                    rules={required}
                  />
                  {/*<FormInput
                    name={'process_data_permission'}
                    placeholder={''}
                    label={
                      <div className="input__label">
                        Даю согласие на обработку моих персональных данных в
                        соответствии с Политикой ООО «Нестле Россия» по защите{' '} 
                        <a
                          className="input__link"
                          href="/personal.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          персональных данных
                        </a>
                        .*
                      </div>
                    }
                    type="checkbox"
                    rules={required}
                  />*/}
                </div>
                {/*<div className="form__field form__field-checkbox">
                  <FormInput
                    name={'agreement_permission'}
                    placeholder={''}
                    label={
                      <div className="input__label">
                        Нажимая на кнопку «Зарегистрироваться», я принимаю
                        условия{' '}
                        <a
                          className="input__link"
                          href="https://www.purina.ru/data-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Соглашения о конфиденциальности
                        </a>
                        .*
                      </div>
                    }
                    type="checkbox"
                    rules={required}
                  />
                  </div>*/}
                <div className='form__field form__field-checkbox'>
                  <FormInput
                    name={'send_email_permission'}
                    placeholder={''}
                    label={'Согласен на получение рекламно-информационной рассылки по электронной почте в отношении брендов и мероприятий ООО «Нестле Россия».'}
                    type='checkbox'
                  />
                </div>
                <div className='form__btn-wrapper form__btn-wrappreg'>
                  <button type='submit'>
                    <Button
                      text='ЗАРЕГИСТРИРОВАТЬСЯ'
                      type='submit'
                      additionalClass='btn__reg'
                    />
                  </button>
                </div>

                <div className='form__disclaimer'>
                  Поля, отмеченные * , обязательны для заполнения. Завершение
                  регистрации без них невозможно.
                </div>
              </form>
            )}
          />
        </div>
        <Popup
          opened={this.state.personalPopupIsOpened}
          closeBtnFunc={this.togglePersonalPopup.bind(this)}
          type='fixed'
        >
          <Scrollbars style={{ width: '100%', height: '250px' }}>
            <p>
              Заполняя анкету, я подтверждаю, что я даю свое согласие ООО
              «Нестле Россия» на обработку моих персональных данных,
              содержащихся в настоящей анкете (далее – «ПДн») и подтверждаю, что
              все указанные в настоящей анкете данные верны: Настоящее согласие
              на обработку моих ПДн предоставляется Оператору и уполномоченным
              им лицам, действующим на основании соглашений о неразглашении
              конфиденциальных данных, в отношении следующих действий: сбор,
              запись, систематизация, накопление, хранение, уточнение
              (обновление, изменение), извлечение, использование, передача
              (распространение, предоставление, доступ), трансграничная передача
              на территорию иностранных государств, являющихся сторонами
              Конвенции Совета Европы о защите физических лиц при
              автоматизированной обработке персональных данных и других действий
              в соответствии с Законом о персональных данных, для целей
              продвижения продукции, маркированной товарным знаком Purina®.
              Обработка ПДн может быть поручена Оператором и (или) ПДн могут
              быть переданы третьим лицам, действующим на основании заключенных
              Оператором договоров. Я уведомлен, что имею право отозвать свое
              согласие, направив письмо с указанием ФИО и адреса, указанных при
              регистрации, на адрес электронной почты contact@ru.nestle.com. Я
              согласен с тем, что предоставленные мной персональные данные будут
              удалены по моему требованию в течение 60 дней с даты получения
              требования об их уничтожении.
            </p>
          </Scrollbars>
        </Popup>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  cities: state.user.cities
})

export default connect(mapStateToProps, {
  loadCities
})(RegistrationForm)
