import { apiAction } from './apiAction'
import objectToFormData from 'object-to-formdata'

export const EDIT_EMAIL_REQUEST = 'EDIT_EMAIL_REQUEST'
export const EDIT_EMAIL_SUCCESS = 'EDIT_EMAIL_SUCCESS'
export const EDIT_EMAIL_FAILURE = 'EDIT_EMAIL_FAILURE'

export const changeEmailAction = userData => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/update/email',
    method: 'POST',
    types: [
      EDIT_EMAIL_REQUEST,
      { type: EDIT_EMAIL_SUCCESS, meta: userData },
      EDIT_EMAIL_FAILURE
    ],
    body: objectToFormData(userData)
  })
}

export const EDIT_EMAIL_POPUP_SHOW = 'EDIT_EMAIL_POPUP_SHOW'
export const EDIT_EMAIL_POPUP_HIDE = 'EDIT_EMAIL_POPUP_HIDE'

export const showEmailPopup = () => ({ type: EDIT_EMAIL_POPUP_SHOW })

export const hideEmailPopup = () => ({ type: EDIT_EMAIL_POPUP_HIDE })
