import { RSAA } from 'redux-api-middleware'
import { getAbsoluteUrl } from './getAbsoluteUrl'

export default store => next => action => {
  const apiCallAction = action[RSAA]
  if (typeof apiCallAction === 'undefined') {
    return next(action)
  }

  let { endpoint } = apiCallAction

  if (endpoint.search('http[s]*://') > -1 || endpoint === '/config.json') {
    return next(action)
  }

  endpoint +=
    endpoint.indexOf('_format=json') !== -1
      ? ''
      : endpoint.indexOf('?') !== -1
        ? '&_format=json'
        : '?_format=json'

  const state = store.getState()
  const {
    config: { apiRoot }
  } = state
  const absoluteEndpoint = getAbsoluteUrl(apiRoot, endpoint)
  const newAction = {
    ...apiCallAction,
    endpoint: absoluteEndpoint
  }
  const newApiCall = {
    [RSAA]: newAction
  }

  return next(newApiCall)
}
