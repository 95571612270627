import { all, call, fork, take } from 'redux-saga/effects'
import { router } from 'redux-saga-router'
import queryString from 'query-string'
import mainSaga from './mainSaga'
import petsSaga from './petsSaga'
import petSaga from './petSaga'
import favoritesSaga from './favoritesSaga'
import partnerSaga from './partnerSaga'
import shopSaga from './shopSaga'
import clubSaga from './clubSaga'
import configSaga from './configSaga'
import confirmSaga from './confirmSaga'
import loginSaga from './loginSaga'
import trainingSaga from './trainingSaga'
import testSaga from './testSaga'
import storySaga from './storySaga'
import productsSaga from './productsSaga'
import organizationsSaga from './organizationsSaga'
import faqSaga from './faqSaga'
import navSaga from './navSaga'
import volunteeringSaga from './volunteeringSaga'
import lkSaga from './lkSaga'
import librarySaga from './librarySaga'
import partnersSaga from './partners'
import articlesSaga, { ourArticlesSaga } from './articlesSaga'
import articleSaga from './articleSaga'
import events from './events'
import watchRemoveAccount from './removeAccountSaga'
import { READ_CONFIGURATION } from '../actions/index'
import userSaga from './userSaga'
import otherPetsSaga from './otherPetsSaga'

const createRootSaga = (history, dispatch) => {
  const routes = {
    '/': function* mainPageSaga() {
      yield fork(mainSaga)
    },
    '/pets': function* petsPageSaga({ id = history.location.search }) {
      yield fork(petsSaga, { id })
    },
    '/pets-owners': function* petsPageSaga({ id = history.location.search }) {
      yield fork(petsSaga, { id })
    },
    '/pets/*': function* petsPageSaga({
                                        pathname = history.location.pathname,
                                        id = history.location.search
                                      }) {
      yield call(petsSaga, { pathname, id })
      yield call(otherPetsSaga, { pathname, id })
    },
    '/favorites': function* favoritesPageSaga(
      {
        id = history.location.search
      }) {
        yield fork(favoritesSaga, { id })
      },

    '/favorites/*': function* favoritesPageSaga({
                                                  pathname = history.location.pathname,
                                                  id = history.location.search
                                                }) {
      yield fork(favoritesSaga, { pathname, id })
    },
    '/school': function* schoolPageSaga() {
      yield fork(trainingSaga)
      yield fork(testSaga)
    },
    '/about': function* aboutPageSaga() {
      yield fork(mainSaga)
    },
    '/pet/:id': function* petPageSaga({ id }) {
      yield fork(petSaga, { id })
    },
    '/fonds/:id': function* partnerPageSaga({ id }) {
      yield fork(partnerSaga, { id })
    },
    '/shops/:id': function* shopPageSaga({ id }) {
      yield fork(shopSaga, { id })
    },
    '/vet/:id': function* shopPageSaga({ id }) {
      yield fork(shopSaga, { id })
    },
    '/club': function* clubPageSaga() {
      yield fork(clubSaga)
    },
    '/FAQ': function* faqPageSaga() {
      yield fork(faqSaga)
    },
    '/products': function* productsPageSaga() {
      yield fork(productsSaga)
    },
    '/volunteering': function* volunteeringPageSaga() {
      yield fork(volunteeringSaga)
    },
    '/lk': function* lkPageSaga() {
      yield fork(lkSaga, history)
    },
    '/library/:id': function* libraryPageSaga({ id }) {
      yield fork(librarySaga, { id })
    },
    '/partners': function* partnersPage() {
      yield fork(partnersSaga)
      yield fork(organizationsSaga)
    },
    '/events': function* eventsPage() {
      yield fork(events)
    },
    '/articles/:id': function* articlesPage({
                                              path = history.location.pathname
                                            }) {
      yield fork(articleSaga, { path })
    },
    '/articles': function* articlesPage({ id = history.location.search }) {
      yield fork(articlesSaga, { id })
    },
    '/our-articles/:id': function* articlesPage({path = history.location.pathname}) {
      yield fork(articleSaga, { path })
      yield fork(petsSaga,{path})
    },
    '/our-articles': function* articlesPage({ id = history.location.search }) {
      yield fork(ourArticlesSaga, { id })
    },
    '/stories': function* storiesPage() {
      yield fork(storySaga)
    }
  }
  return function* routesSaga() {
    const pathname = queryString.parse(window.location.pathname)
    const search = queryString.parse(window.location.search)
    if (Object.keys(pathname)[0] === '/confirm' && search && search.hash) {
      yield fork(confirmSaga, { search })
    }
    yield fork(router, history, routes)
  }
}

export default function* root(history, dispatch) {
  yield call(configSaga)
  yield take(READ_CONFIGURATION)
  yield all([
    call(loginSaga, history),
    call(navSaga),
    call(userSaga),
    call(watchRemoveAccount),
    fork(createRootSaga(history, dispatch))
  ])
}
