export const READ_CONFIGURATION = 'READ_CONFIGURATION'

const receiveConfig = config => ({
  type: READ_CONFIGURATION,
  config: config
})

export const CLOSE_INFO_POPUP = 'CLOSE_INFO_POPUP'

export const closeInfoPopup = () => ({
  type: CLOSE_INFO_POPUP
})

export const loadConfig = () => dispatch => {
  return dispatch(receiveConfig({ apiRoot: '' }));
}

export const TOGGLE_LOGIN_POPUP = 'TOGGLE_LOGIN_POPUP'

export const toggleLoginPopup = (popupIsOpened, tab = 'login') => {
  !popupIsOpened
    ? document.documentElement.classList.add('locked')
    : document.documentElement.classList.remove('locked')
  return {
    type: TOGGLE_LOGIN_POPUP,
    payload: {
      isOpened: popupIsOpened,
      tab: tab
    }
  }
}

export const CLOSE_PETS_MESSAGE = 'CLOSE_PETS_MESSAGE'

export const closePetsMessage = () => ({
  type: CLOSE_PETS_MESSAGE
})

export const OPEN_PETS_MESSAGE = 'OPEN_PETS_MESSAGE'

export const openPetsMessage = () => ({
  type: OPEN_PETS_MESSAGE
})

export const NEED_SEND_PET_ACTION = 'NEED_SEND_PET_ACTION'

export const needSendPetAction = val => ({
  type: NEED_SEND_PET_ACTION,
  payload: val
})

export const IS_ERROR_PAGE = 'IS_ERROR_PAGE'

export const setErrorPage = val => {
  return ({
    type: IS_ERROR_PAGE,
    payload: val
  })
}
