import React, { Component } from 'react'
import './style.scss'

class Wrapper extends Component {
  render() {
    return (
      <div
        className={`content-wrapper ${
          this.props.className ? this.props.className : ''
        }`}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Wrapper
