import { RSAA } from 'redux-api-middleware'

export const apiAction = (dispatch, getState, action) => {
  let headers = action.headers ? action.headers : {}
  if (getState().user && getState().user.token && !action.noAuthorization) {
    headers.Authorization =
      'Bearer ' +
      (getState().user && getState().user.token ? getState().user.token : '')
  }

  headers['X-Requested-With'] = 'XMLHttpRequest'

  return dispatch({
    [RSAA]: {
      endpoint: action.endpoint + (action.endpoint.indexOf('?') !== -1 ? '&_format=json' : '?_format=json'),
      method: action.method,
      headers: headers,
      types: action.types,
      body: action.body ? action.body : undefined
    }
  })
}
