import { put } from 'redux-saga/effects'
import { getPartnersAction } from '../actions/partners'
import { getPetsByTypeAction } from '../actions/pets'
import { getBlocks, getStories } from '../actions/story'

function* mainSaga() {
  yield put(getPartnersAction('fonds'))
  yield put(getPetsByTypeAction('calm'))
  yield put(getStories())
  yield put(getBlocks())
}

export default mainSaga
