import { put, take } from 'redux-saga/effects'
import { GET_CONFIRM_SUCCESS, getConfirm } from '../actions/user'
import { getPartnersAction } from '../actions/partners'
import { getPetsByTypeAction } from '../actions/pets'

function* confirmSaga(params = []) {
  yield put(getConfirm(params.search))
  while (true) {
    yield take(GET_CONFIRM_SUCCESS)
    yield put(getPartnersAction('fonds'))
    yield put(getPetsByTypeAction('calm'))
  }
}

export default confirmSaga
