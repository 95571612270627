export const regWebStat = () => {
  console.log('gaEvent:Registration')
  window.dataLayer.push({
    'event': 'gaEvent',
    'eventName': 'Registration'
  })
  if (window.wamf) {
    window.wamf.push('register', 'Yes')
    window.wamf.send()
  }
}
export const regCuratorStat = () => {
  window.dataLayer.push({
    'event': 'gaEvent',
    'eventName': 'Curator'
  })
}

export const loginWebStat = () => {
  if (window.wamf) {
    window.wamf.push('login', 'Yes')
    window.wamf.send()
  }
}

export const getPetWebStat = () => {
  if (window.wamf) {
    window.wamf.push('get_pet', 'Yes')
    window.wamf.send()
  }
}

export const favoriteWebStat = () => {
  if (window.wamf) {
    window.wamf.push('favorite', 'Yes')
    window.wamf.send()
  }
}

export const partnerWebStat = () => {
  if (window.wamf) {
    window.wamf.push('partner', 'Yes')
    window.wamf.send()
  }
}

export const volunteerWebStat = () => {
  if (window.wamf) {
    window.wamf.push('volunteer', 'Yes')
    window.wamf.send()
  }
}

export const testWebStat = () => {
  if (window.wamf) {
    window.wamf.push('test', 'Yes')
    window.wamf.send()
  }
}
