import { GET_TRAINING_INFO_REQUEST, GET_TRAINING_INFO_SUCCESS } from '../actions/training'

const initialState = {
  trainingInfo: []
}

export const training = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case GET_TRAINING_INFO_REQUEST:
      return { ...state, trainingInfo: [] }
    case GET_TRAINING_INFO_SUCCESS:
      if (Array.isArray(action.payload)) {
        return { ...state, trainingInfo: action.payload }
      } else {
        return { ...state }
      }
    default:
      return state
  }
}
