import { apiAction } from './apiAction'

export const GET_ARTICLESLIST_REQUEST = 'GET_ARTICLESLIST_REQUEST'
export const GET_ARTICLESLIST_SUCCESS = 'GET_ARTICLESLIST_SUCCESS'
export const GET_ARTICLESLIST_FAILURE = 'GET_ARTICLESLIST_FAILURE'

export const getArticles = page => (dispatch, getState) => {
  const title = getState().articles.title
  const params = page
    ? `${page}${title ? `&title=${title}` : ''}`
    : `${title ? `?title=${title}` : ''}`

  apiAction(dispatch, getState, {
    endpoint: `/api/articles${params}`,
    method: 'GET',
    types: [
      GET_ARTICLESLIST_REQUEST,
      {
        type: GET_ARTICLESLIST_SUCCESS,
        meta: {
          searching: title ? true : false
        }
      },
      GET_ARTICLESLIST_FAILURE
    ],
    noAuthorization: true
  })
}

export const GET_OUR_ARTICLESLIST_REQUEST = 'GET_OUR_ARTICLESLIST_REQUEST'
export const GET_OUR_ARTICLESLIST_SUCCESS = 'GET_OUR_ARTICLESLIST_SUCCESS'
export const GET_OUR_ARTICLESLIST_FAILURE = 'GET_OUR_ARTICLESLIST_FAILURE'

export const getOurArticles = page => (dispatch, getState) => {
  const title = getState().articles.title
  const params = page
    ? `${page}${title ? `&title=${title}` : ''}`
    : `${title ? `?title=${title}` : ''}`

  apiAction(dispatch, getState, {
    endpoint: `/api/our-articles${params}`,
    method: 'GET',
    types: [
      GET_OUR_ARTICLESLIST_REQUEST,
      {
        type: GET_OUR_ARTICLESLIST_SUCCESS,
        meta: {
          searching: title ? true : false
        }
      },
      GET_OUR_ARTICLESLIST_FAILURE
    ],
    noAuthorization: true
  })
}

export const GET_CURRENT_ARTICLE_REQUEST = 'GET_CURRENT_ARTICLE_REQUEST'
export const GET_CURRENT_ARTICLE_SUCCESS = 'GET_CURRENT_ARTICLE_SUCCESS'
export const GET_CURRENT_ARTICLE_FAILURE = 'GET_CURRENT_ARTICLE_FAILURE'

export const getCurrentArticle = path => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: `/api${path}`,
    method: 'GET',
    types: [
      GET_CURRENT_ARTICLE_REQUEST,
      GET_CURRENT_ARTICLE_SUCCESS,
      GET_CURRENT_ARTICLE_FAILURE
    ],
    noAuthorization: true
  })
}

export const GET_CURRENT_ARTICLE_SIMILAR_REQUEST =
  'GET_CURRENT_ARTICLE_SIMILAR_REQUEST'
export const GET_CURRENT_ARTICLE_SIMILAR_SUCCESS =
  'GET_CURRENT_ARTICLE_SIMILAR_SUCCESS'
export const GET_CURRENT_ARTICLE_SIMILAR_FAILURE =
  'GET_CURRENT_ARTICLE_SIMILAR_FAILURE'

export const getCurrentArticleSimilar = path => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: `/api/getreference${path}`,
    method: 'GET',
    types: [
      GET_CURRENT_ARTICLE_SIMILAR_REQUEST,
      GET_CURRENT_ARTICLE_SIMILAR_SUCCESS,
      GET_CURRENT_ARTICLE_SIMILAR_FAILURE
    ],
    noAuthorization: true
  })
}

export const GET_RANDOM_ARTICLES_REQUEST = 'GET_RANDOM_ARTICLES_REQUEST'
export const GET_RANDOM_ARTICLES_SUCCESS = 'GET_RANDOM_ARTICLES_SUCCESS'
export const GET_RANDOM_ARTICLES_FAILURE = 'GET_RANDOM_ARTICLES_FAILURE'

export const getRandomArticles = path => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: `/api/getsimilar${path}`,
    method: 'GET',
    types: [
      GET_RANDOM_ARTICLES_REQUEST,
      GET_RANDOM_ARTICLES_SUCCESS,
      GET_RANDOM_ARTICLES_FAILURE
    ],
    noAuthorization: true
  })
}

export const SET_SEARCH_ARTICLE_TITLE = 'SET_SEARCH_ARTICLE_TITLE'

export const setSearchArticleTitle = (value = null) => ({
  type: SET_SEARCH_ARTICLE_TITLE,
  meta: { value: value }
})
