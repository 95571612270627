import {
  GET_PARTNER_FAILURE,
  GET_PARTNER_REQUEST,
  GET_PARTNER_SUCCESS,
  GET_PARTNERS_REQUEST,
  GET_PARTNERS_SUCCESS,
  GET_SHOP_FAILURE,
  GET_SHOP_REQUEST,
  GET_SHOP_SUCCESS,
  SEND_PARTNERS_FAILURE,
  SEND_PARTNERS_REQUEST,
  SEND_PARTNERS_SUCCESS,
  SET_CURRENT_PARTNER_SUCCESS
} from '../actions/partners'

const initialState = {
  partners: [],
  cities: undefined,
  currentCity: {
    value: 0,
    label: 'Москва'
  },
  currentPartner: undefined
}

export const partners = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case GET_PARTNERS_REQUEST:
      return { ...state, partners: [] }
    case GET_PARTNERS_SUCCESS:
      const partners = action.payload.results
      if (partners && Array.isArray(partners)) {
        return { ...state, partners: partners }
      } else {
        return { ...state }
      }
    case GET_PARTNER_REQUEST:
      return { ...state, currentPartner: undefined }
    case GET_PARTNER_SUCCESS:
      return { ...state, currentPartner: action.payload }
    case GET_PARTNER_FAILURE:
      return { ...state, error: true }
    case GET_SHOP_REQUEST:
      return { ...state, currentPartner: undefined }
    case GET_SHOP_SUCCESS:
      return { ...state, currentPartner: action.payload }
    case GET_SHOP_FAILURE:
      return { ...state, error: true }
    case SEND_PARTNERS_REQUEST:
      return { ...state, partnersRequest: true }
    case SEND_PARTNERS_SUCCESS:
    case SEND_PARTNERS_FAILURE:
      return { ...state, partnersRequest: false }
    case SET_CURRENT_PARTNER_SUCCESS:
      return { ...state, currentPartner: action.payload.partner }
    default:
      return state
  }
}
