import { put } from 'redux-saga/effects'
import { getFaqAction } from '../actions/faq'
import { getPartnersAction } from '../actions/partners'

function* faqSaga() {
  yield put(getPartnersAction('fonds'))
  yield put(getFaqAction())
}

export default faqSaga
