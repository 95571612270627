import React, { Component } from 'react'
import { Bounce, gsap } from 'gsap'
import PropTypes from 'prop-types'
import './style.scss'
import GSAP from 'react-gsap-enhancer'

gsap.config({
  autoSleep: 60,
  force3D: false,
  nullTargetWarn: false,
  trialWarn: false,
  units: {left: "%", top: "%", rotation: "rad"}
});

class TextAnimation extends Component {
  static propTypes = {
    text: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.errorAnim = this.errorAnim.bind(this)
  }

  componentDidMount() {
    this.errorAnim = this.addAnimation(this.errorAnim)
  }

  errorAnim({ target }) {
    let text = target.find({ name: 'text' })
    return gsap.timeline({ repeat: 0 })
      .from(text, 0.1, { scale: 2, y: '+=20' })
      .to(text, 0.1, { scale: 1, rotation: -10 })
      .to(text, 0.2, { rotation: 0, ease: Bounce.easeOut })
  }

  render() {
    const { text } = this.props
    return (
      <span className={`text-animation ${this.props.className || ''}`}>
        <span name='text'>{text}</span>
      </span>
    )
  }
}

export default GSAP()(TextAnimation)
