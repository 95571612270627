import React, { Component, lazy } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import Header from '../components/header'
import Footer from '../components/footer'
import Nav from '../components/nav'
import Wrapper from '../components/wrapper'
import ScrollTop from '../components/scrollTop'

import { closeInfoPopup, toggleLoginPopup } from '../actions'
import {
  loginAction,
  logoutAction,
  registrationAction,
  registrationConfirm,
  restoreAction,
  restoreCompleteAction,
  submitCodeAction
} from '../actions/user'
import { closePetAnswerPopup, openPetAnswerPopup } from '../actions/pets'
import './style.scss'
import PopupLive from '../components/popup/PopupLive'
import InfoPopup from '../components/popup/popupInfo'

const PetPage = lazy(() => import('./pages/pet'));
const AboutPage = lazy(() => import('./pages/about'));
const StoriesPage = lazy(() => import('./pages/stories'));
const EventsPage = lazy(() => import('./pages/events'));
const PetsPage = lazy(() => import('./pages/pets'));
const PetsOwnersPage = lazy(() => import('./pages/owners'));
const FavoritesPage = lazy(() => import('./pages/favorites'));
const MainPage = lazy(() => import('./pages/main'));
const PartnersPage = lazy(() => import('./pages/partners'));
const SchoolPage = lazy(() => import('./pages/school'));
const LK = lazy(() => import('./pages/lk'));
const PartnerPage = lazy(() => import('./pages/partner'));
const ShopPage = lazy(() => import('./pages/shop'));
const VetPage = lazy(() => import('./pages/vet'));
const VolunteeringPage = lazy(() => import('./pages/volunteering'));
const ClubPage = lazy(() => import('./pages/club'));
const SitemapPage = lazy(() => import('./pages/sitemap'));
const FAQPage = lazy(() => import('./pages/FAQ'));
const ProductsPage = lazy(() => import('./pages/products'));
const Library = lazy(() => import('./pages/library'));
const Feedback = lazy(() => import('./pages/feedback'));
const ErrorPage = lazy(() => import('./pages/errorpage'));
const OurArticlesPage = lazy(() => import('./pages/our-articles'));
const OurArticlePage = lazy(() => import('./pages/our-article'));

class App extends Component {
  state = {
    counter: 0,
    isLiveOpened: false
  }

  closeLivePopup = () => {
    this.setState({
      isLiveOpened: false
    })
  }

  handleClick() {
  }

  componentDidMount() {
    const invite = this.props.location.search.replace(/\?/, '').split('=')
    if (invite[0] === 'invite') {
      window.localStorage.setItem('invite', invite[1])
    }
  }

  render() {
    const {
      loginAction,
      registrationAction,
      registrationConfirm,
      restoreAction,
      submitCodeAction,
      user,
      isAuth = false,
      isError = false,
      isArticleError = false,
      logoutAction,
      infoPopup,
      closeInfoPopup,
      toggleLoginPopup,
      loginPopupIsOpened,
      loginPopupTab,
      restoreCompleteAction,
      pathname,
      isFinishedAnswersPopup,
      isOpenedAnswersPopup,
      countVisitPagePet,
      openPetAnswerPopup,
      closePetAnswerPopup,
      isErrorPage = false
    } = this.props

    return (
      <div className='App'>
        <div className='l-page'>
          <Header
            userName={
              user.user && user.user.name && user.user.name[0]
                ? user.user.name[0].value
                : undefined
            }
          />
          <Wrapper>
            <Switch>
              <Route exact path='/(vet|shops|fonds)/*'>
                <Nav
                  btnAction={this.handleClick.bind(this)}
                  submitLoginAction={loginAction}
                  submitRegistrationAction={registrationAction}
                  registrationConfirm={registrationConfirm}
                  submitRestoreAction={restoreAction}
                  restoreCompleteAction={restoreCompleteAction}
                  user={user}
                  isAuth={isAuth}
                  logout={logoutAction}
                  toggleLoginPopup={toggleLoginPopup}
                  loginPopupIsOpened={loginPopupIsOpened}
                  loginPopupTab={loginPopupTab}
                  isError={isError}
                  isFinishedAnswersPopup={isFinishedAnswersPopup}
                  isOpenedAnswersPopup={isOpenedAnswersPopup}
                  countVisitPagePet={countVisitPagePet}
                  openPetAnswerPopup={openPetAnswerPopup}
                  closePetAnswerPopup={closePetAnswerPopup}
                />
              </Route>
              <Route exact path='*' render={(props) => {
                const isError = props && props.location && props.location.pathname.includes('our-articles') && isArticleError || isErrorPage;
                return (
                  <Nav
                    btnAction={this.handleClick.bind(this)}
                    submitLoginAction={loginAction}
                    submitRegistrationAction={registrationAction}
                    registrationConfirm={registrationConfirm}
                    submitRestoreAction={restoreAction}
                    restoreCompleteAction={restoreCompleteAction}
                    user={user}
                    isError={isError}
                    isAuth={isAuth}
                    logout={logoutAction}
                    toggleLoginPopup={toggleLoginPopup}
                    loginPopupIsOpened={loginPopupIsOpened}
                    loginPopupTab={loginPopupTab}
                    isFinishedAnswersPopup={isFinishedAnswersPopup}
                    isOpenedAnswersPopup={isOpenedAnswersPopup}
                    countVisitPagePet={countVisitPagePet}
                    openPetAnswerPopup={openPetAnswerPopup}
                    closePetAnswerPopup={closePetAnswerPopup}
                  />
                )
              }} />
            </Switch>
          </Wrapper>
          {/*При добавлении рута, необходимо его прописывать в /server/server.js */}
          <Switch>
            <Redirect from='/:url*(/+)' to={pathname.slice(0, -1)} />
            <Redirect from='/dogs' to='/pets/dogs' />
            <Redirect from='/cats' to='/pets/cats' />
            <Route exact path='/' render={(props) => <MainPage {...props} />} />
            <Route exact path='/confirm' render={(props) => <MainPage {...props} />} />
            <Route exact path='/restore' render={(props) => <MainPage {...props} />} />
            <Route exact path='/favorites' render={(props) => <FavoritesPage {...props} />} />
            <Route exact path='/pets' render={(props) => <PetsPage {...props} />} />
            <Route exact path='/pets/:city' render={(props) => <PetsPage {...props} />} />
            <Route exact path='/pets-owners' render={(props) => <PetsOwnersPage {...props} />} />
            <Route exact path='/pets/:city/*' render={(props) => <PetsPage {...props} />} />
            <Route exact path='/pets/*' render={(props) => <PetsPage {...props} />} />
            <Route exact path='/favorites/*' render={(props) => <FavoritesPage {...props} />} />
            <Route exact path='/pet/:id' render={(props) => <PetPage {...props} />} />
            <Route exact path='/about' render={(props) => <AboutPage {...props} />} />
            <Route exact path='/stories' render={(props) => <StoriesPage {...props} />} />
            <Route exact path='/events' render={(props) => <EventsPage {...props} />} />
            <Route exact path='/partners' render={(props) => <PartnersPage {...props} />} />
            <Route exact path='/school' render={(props) => <SchoolPage {...props} />} />
            {/*<Route exact path='/volunteering' render={(props) => <VolunteeringPage {...props} />} />*/}
            <Route exact path='/club' render={(props) => <ClubPage {...props} />} />
            <Route exact path='/FAQ' render={(props) => <FAQPage {...props} />} />
            <Route exact path='/products' render={(props) => <ProductsPage {...props} />} />
            <Route exact path='/lk' render={(props) => <LK {...props} />} />
            <Route exact path='/fonds/*' render={(props) => <PartnerPage {...props} />} />
            <Route exact path='/shops/*' render={(props) => <ShopPage {...props} />} />
            <Route exact path='/vet/*' render={(props) => <VetPage {...props} />} />
            <Route exact path='/library/*' render={(props) => <Library {...props} />} />
            <Route exact path='/feedback' render={(props) => <Feedback {...props} />} />
            <Route exact path='/our-articles' render={(props) => <OurArticlesPage {...props} />} />
            <Route exact path='/our-articles/:id' render={(props) => <OurArticlePage {...props} />} />
            <Route exact path='/pet-search-sitemap' render={(props) => <SitemapPage {...props} />} />
            <Route exact path='*' render={(props) => <ErrorPage {...props} />} />
          </Switch>
          <InfoPopup
            opened={infoPopup.opened}
            message={infoPopup.message}
            text={infoPopup.text}
            type={infoPopup.type}
            openTypeOfForm={infoPopup.openTypeOfForm}
            customCloseButtonText={infoPopup.customCloseButtonText}
            phone={user.username}
            onClose={closeInfoPopup}
            toggleLoginPopup={toggleLoginPopup}
            submitCodeAction={submitCodeAction}
            logoutAction={logoutAction}
          />
          <PopupLive
            isLiveOpened={this.state.isLiveOpened}
            closeLivePopup={this.closeLivePopup}
          />
        </div>
        <ScrollTop/>
        <Footer location={this.props.location.pathname} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  pathname: state.routing.location.pathname,
  user: state.user,
  isAuth: state.user.isAuth,
  isError: state.partners.error,
  isArticleError: state.articles.error || state.error.error,
  isErrorPage: state.error.error,
  infoPopup: state.infoPopup,
  loginPopupIsOpened: state.loginPopup.opened,
  loginPopupTab: state.loginPopup.tab,
  isFinishedAnswersPopup: state.pets.isFinishedAnswersPopup,
  isOpenedAnswersPopup: state.pets.isOpenedAnswersPopup,
  countVisitPagePet: state.pets.countVisitPagePet
})

export default withRouter(
  connect(mapStateToProps, {
    loginAction,
    registrationAction,
    registrationConfirm,
    restoreAction,
    logoutAction,
    closeInfoPopup,
    toggleLoginPopup,
    restoreCompleteAction,
    submitCodeAction,
    openPetAnswerPopup,
    closePetAnswerPopup
  })(App)
)
