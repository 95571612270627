export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS'

export const getOrganizations = () => ({
  type: GET_ORGANIZATIONS
})

export const SET_CURRENT_ORGANIZATION_SUCCESS =
  'SET_CURRENT_ORGANIZATION_SUCCESS'

export const setCurrentOrganization = organization => ({
  type: SET_CURRENT_ORGANIZATION_SUCCESS,
  payload: {
    organization
  }
})
