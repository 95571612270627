import { put, takeEvery } from 'redux-saga/effects'

import { logoutAction } from '../actions/user'

function* watchRemoveAccount() {
  yield takeEvery('REMOVE_ACCOUNT_SUCCESS', removeAccountSaga)
}

function* removeAccountSaga() {
  yield put(logoutAction())
}

export default watchRemoveAccount
