import React, { Suspense } from 'react'
import { render } from 'react-dom/cjs/react-dom.production.min'
import './index.scss'
import { BrowserRouter } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'
import Root from './containers/root'
import configureStore from './store'
import saga from './sagas/index'
import Preloader from './components/preloader'
import Header from './components/header'

const history = createHistory()
const store = configureStore(history)
store.runSaga(saga, history, store.dispatch)

render(
  <BrowserRouter>
    <Suspense fallback={
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "100vh"}}>
        <Header />
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", flex: "1 1 auto"}}>
          <Preloader />
        </div>
      </div>
    }>
      <Root store={store} history={history} />
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
)
