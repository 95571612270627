import { IS_ERROR_PAGE } from '../actions/index'

const initialState = {
  error: false
}

export const error = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case IS_ERROR_PAGE:
      return {
        ...state,
        error: payload
      }
    default:
      return state
  }
}
