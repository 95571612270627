import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FistStep from './1step'
import SecondStep from './2step'
import '../style.scss'
import './style.scss'

class RestorePasswordForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    restoreCompleteAction: PropTypes.func.isRequired,
    active: PropTypes.bool,
    restoreNextStep: PropTypes.bool
  }

  state = {
    restoreMod: false,
    phone: ''
  }

  componentDidUpdate = prevProps => {
    if (prevProps.restoreNextStep !== this.props.restoreNextStep) {
      this.setState({
        restoreMod: true
      })
    }
  }

  setPhone = phone => {
    this.setState({ phone: phone })
  }

  render() {
    const { active = true, submitAction, restoreCompleteAction } = this.props
    return (
      <div className={'form restore-form ' + (active ? 'active' : 'inactive')}>
        <span className='form-title'>Восcтановление пароля</span>
        {!this.state.restoreMod ? (
          <FistStep
            submitAction={submitAction}
            active={active}
            setPhone={this.setPhone}
          />
        ) : (
          <SecondStep
            restoreCompleteAction={restoreCompleteAction}
            active={active}
            phone={this.state.phone}
          />
        )}
      </div>
    )
  }
}

export default RestorePasswordForm
