import React, { Component } from 'react'
import './style.scss'
import { Link } from 'react-router-dom'

class ResendSms extends Component {
  constructor(props) {
    super(props)

    this.initialTimer = {
      seconds: '00',
      minutes: '3',
      isOn: false
    }
    this.state = {
      ...this.initialTimer
    }

    this.intervalHandle = () => {
    }
  }

  componentDidMount() {
    this.startCountDown()
  }

  tick = () => {
    var min = Math.floor(this.secondsRemaining / 60)
    var sec = this.secondsRemaining % 60
    this.setState({
      minutes: min,
      seconds: sec
    })
    if (sec < 10) {
      this.setState({
        seconds: '0' + sec
      })
    }
    if (min < 10) {
      this.setState({
        value: '0' + min
      })
    }
    if ((min === 0) & (sec === 0)) {
      this.setState({
        isOn: true
      })
      clearInterval(this.intervalHandle)
    }
    this.secondsRemaining--
  }

  startCountDown = () => {
    this.intervalHandle = setInterval(this.tick, 1000)
    let time = this.initialTimer.minutes
    this.secondsRemaining = time * 60 + Number(this.initialTimer.seconds)
  }

  resendSms = e => {
    e.preventDefault()
    if (e.target.className.indexOf('disabled') !== -1) {
      return false
    } else {
      this.props.sendFunc()
      this.setState({
        ...this.initialTimer
      })
      this.startCountDown()
    }
  }

  render() {
    return (
      <div className='resendSms'>
        <Link
          to="#"
          id='resendSmsLink'
          className={`${this.state.isOn ? '' : 'disabled'}`}
          onClick={this.resendSms}
        >
          Отправить код повторно
        </Link>
        <p>{`${this.state.minutes}:${this.state.seconds}`}</p>
      </div>
    )
  }
}

export default ResendSms
