export const getAbsoluteUrl = (apiRoot, endpoint) => {
  if (endpoint.startsWith('http')) {
    return endpoint
  }
  const lastChar = apiRoot.length > 0 ? apiRoot[apiRoot.length - 1] : null
  const absoluteEndpoint =
    lastChar == null || lastChar === '/'
      ? apiRoot + endpoint
      : apiRoot + '/' + endpoint

  return absoluteEndpoint
}

export const getAbsoluteUrlFn = apiRoot => endpoint =>
  getAbsoluteUrl(apiRoot, endpoint)
