import { GET_FAQ_SUCCESS } from '../actions/faq'

const initialState = {
  materials: []
}

export const faq = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case GET_FAQ_SUCCESS:
      return { ...state, materials: action.payload }
    default:
      return state
  }
}
