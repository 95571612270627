import { put, select, take } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import {
  GET_PETS_SUCCESS,
  getPetsAction,
  parseURL,
  rememberFiltersSet,
  testUid
} from '../actions/favorites'
import { closePetsMessage, openPetsMessage } from '../actions'
import { pets } from '../reducers/selectors/'
import { getPetCities } from '../actions/getPetCities.js'
import { getPetFonds } from '../actions/getPetFonds.js'

function* favoritesSaga(params = []) {
  let id = 1
  if (params.id && params.id.indexOf('?page=') !== -1) {
    const paramsId = Number(params.id.replace('?page=', ''))
    id = typeof paramsId === 'number' ? paramsId : 1
  }

  if (params.pathname) {
    const petsSelector = yield select(pets)
    const urlParams = params.pathname ? params.pathname.split('/') : ''
    const filteredUrlParams = urlParams.filter(element => element !== '')
    if (
      !petsSelector.currentFiltersPreset &&
      petsSelector.params &&
      filteredUrlParams &&
      filteredUrlParams.length > 1
    ) {
      yield put(parseURL(filteredUrlParams))
    }
  }

  const petsSelector = yield select(pets)

  if (params.pet_type) {
    yield put(rememberFiltersSet('pet_type=' + params.pet_type + '&'))
  } else {
    yield put(rememberFiltersSet(petsSelector.currentFiltersPreset))
  }

  yield put(
    getPetsAction(
      petsSelector.currentFiltersPreset
        ? petsSelector.currentFiltersPreset
        : '',
      12,
      id - 1
    )
  )
  yield put(getPetCities())
  yield put(getPetFonds())
  yield put(closePetsMessage())
  yield take(GET_PETS_SUCCESS)
  yield put(openPetsMessage())
  yield delay(3000)
  yield put(closePetsMessage())
  yield put(testUid())
}

export default favoritesSaga
