import { apiAction } from './apiAction'
import objectToFormData from 'object-to-formdata'

export const SEND_FEEDBACK_REQUEST = 'SEND_FEEDBACK_REQUEST'
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS'
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE'

export const sendFeedback = feedbackJson => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/feedback',
    method: 'POST',
    types: [
      SEND_FEEDBACK_REQUEST,
      SEND_FEEDBACK_SUCCESS,
      SEND_FEEDBACK_FAILURE
    ],
    body: objectToFormData(feedbackJson),
    noAuthorization: true
  })
}
