import { put, take, select, all } from 'redux-saga/effects'
import {
  addVisitPagePet, GET_PET_FAILURE, GET_PET_FLAG_SUCCESS,
  GET_PET_SUCCESS,
  getOtherPetsAction,
  getPetAction,
  getPetFavoritesFlag
} from '../actions/pets'
import { POST_CURATOR_SUCCESS } from '../actions/curator'
import { getMeAction } from '../actions/user'

export const currentPet = state => state.pets.currentPet
const countVisitPagePet = state => state.pets.countVisitPagePet

function* petSaga(params) {
  let getCountVisitPagePet = yield select(countVisitPagePet)

  // Если количество посещений меньше 2,
  // то запустить экшн счетчика посещения страницы анкет питомцев 
  if (getCountVisitPagePet < 2) {
    yield put(addVisitPagePet())
  }

  yield put(getPetAction(params.id));
  yield put(getPetFavoritesFlag(params.id))

  const { success } = yield all({
    success: take(GET_PET_SUCCESS)
  })

  if (success) {
    let getCurrentPet = yield select(currentPet)

    const query = [
      getCurrentPet.field_city.length
        ? `pet_city=${getCurrentPet.field_city[0].target_id}`
        : '',
      getCurrentPet.field_pet_type.length
        ? `pet_type=${getCurrentPet.field_pet_type[0].value}`
        : 'type',
      getCurrentPet.field_pet_sex.length
        ? `pet_sex=${getCurrentPet.field_pet_sex[0].value}`
        : 'sex',
      getCurrentPet.field_pet_color.length
        ? `pet_color[]=${getCurrentPet.field_pet_color
          .map(el => el.value)
          .join('&pet_color[]=')}`
        : 'color',
      `pet_id=${params.id}`
    ]
    yield put(getOtherPetsAction(query.filter(Boolean).join('&')))
  }

  while (true) {
    yield take(POST_CURATOR_SUCCESS)
    yield put(getMeAction())
  }
}

export default petSaga
