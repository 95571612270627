import { READ_CONFIGURATION } from '../actions/'

const initialState = {
  data: undefined
}

export const config = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case READ_CONFIGURATION:
      return { ...state, apiRoot: action.config.apiRoot }
    default:
      return state
  }
}
