import { apiAction } from './apiAction'

export const GET_TEST_QESTION_REQUEST = 'GET_TEST_QESTION_REQUEST'
export const GET_TEST_QESTION_SUCCESS = 'GET_TEST_QESTION_SUCCESS'
export const GET_TEST_QESTION_FAILURE = 'GET_TEST_QESTION_FAILURE'

export const getTestQuestion = id => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/test?id=' + id,
    method: 'GET',
    types: [
      GET_TEST_QESTION_REQUEST,
      GET_TEST_QESTION_SUCCESS,
      GET_TEST_QESTION_FAILURE
    ]
  })
}

export const POST_TEST_ANSWER_REQUEST = 'POST_TEST_ANSWER_REQUEST'
export const POST_TEST_ANSWER_SUCCESS = 'POST_TEST_ANSWER_SUCCESS'
export const POST_TEST_ANSWER_FAILURE = 'POST_TEST_ANSWER_FAILURE'

export const postTestAnswerAction = answer => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/test',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    types: [
      {
        meta: { questionId: answer.questionId, answerId: answer.answerId },
        type: POST_TEST_ANSWER_REQUEST
      },
      {
        meta: { questionId: answer.questionId, answerId: answer.answerId },
        type: POST_TEST_ANSWER_SUCCESS
      },
      {
        meta: { questionId: answer.questionId, answerId: answer.answerId },
        type: POST_TEST_ANSWER_FAILURE
      }
    ],
    body: JSON.stringify(answer)
  })
}

export const COMPLETE_TEST_REQUEST = 'COMPLETE_TEST_REQUEST'
export const COMPLETE_TEST_SUCCESS = 'COMPLETE_TEST_SUCCESS'
export const COMPLETE_TEST_FAILURE = 'COMPLETE_TEST_FAILURE'

export const completeTestAction = answers => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/test/complete',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    types: [
      COMPLETE_TEST_REQUEST,
      COMPLETE_TEST_SUCCESS,
      COMPLETE_TEST_FAILURE
    ],
    body: JSON.stringify(answers)
  })
}
