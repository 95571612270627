import React, { Component } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'
import { required } from '../validationRules'
import objectToFormData from 'object-to-formdata'
import FormInput from '../formComponents/input'
import Button from '../../button'
import '../style.scss'
import './style.scss'
import { connect } from 'react-redux'
import { resendSms } from '../../../actions/user'
import ResendSms from '../../resendSms'

class ConfirmForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    active: PropTypes.bool
  }

  submitFormHandler = () => {
    const { submitAction } = this.props
    const formData = objectToFormData(this.state.submittedValues)
    submitAction(formData)
  }

  render() {
    const { active = true } = this.props
    return (
      <div className={'form confirm-form ' + (active ? 'active' : 'inactive')}>
        <span className='form-title'>Введите код из SMS</span>
        <p className='form-text'>{this.props.text}</p>
        <Form
          onSubmit={submittedValues => {
            this.setState(
              { submittedValues },
              this.submitFormHandler.bind(this)
            )
          }}
          initialValues={{ phone: this.props.phone }}
          validate={() => {
          }}
          render={({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <div className='form__field form__field_m-50'>
                <FormInput name={'phone'} placeholder={''} hidden={true} />
                <FormInput
                  name={'code'}
                  placeholder={'123456'}
                  rules={[required]}
                />
              </div>
              <div className='form__btn-wrapper'>
                <button type='submit'>
                  <Button text='Далее' type='submit' />
                </button>
              </div>
              <ResendSms sendFunc={this.props.onResendSms} />
            </form>
          )}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onResendSms: () => dispatch(resendSms())
  }
}
export default connect(null, mapDispatchToProps)(ConfirmForm)
