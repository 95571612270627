import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ParallaxProvider } from 'react-scroll-parallax'
import { ConnectedRouter } from 'react-router-redux'
import App from './App'

const Root = ({ store, history }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ParallaxProvider>
        <App />
      </ParallaxProvider>
    </ConnectedRouter>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default Root
