import { GET_ORGANIZATIONS, SET_CURRENT_ORGANIZATION_SUCCESS } from '../actions/organizations'

const ORGANIZATIONS = [
  {
    title: 'Магазин',
    textInTextarea: 'Расскажите о магазине*'
  },
  {
    title: 'Вет.Клиника',
    textInTextarea: 'Расскажите о вет.клинике*'
  },
  {
    title: 'Фонд/Приют',
    textInTextarea: 'Расскажите о фонде/приюте*'
  }
]

const initialState = {
  organizationsList: ORGANIZATIONS,
  currentOrganization: undefined,
  textInTextarea: 'Текст письма*'
}

export const organizations = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case GET_ORGANIZATIONS:
      return {
        ...state,
        organizationsList: ORGANIZATIONS
      }
    case SET_CURRENT_ORGANIZATION_SUCCESS:
      const organization = ORGANIZATIONS.filter(
        organization => organization.title === action.payload.organization.value
      )
      return {
        ...state,
        currentOrganization: action.payload.organization,
        textInTextarea: organization[0].textInTextarea
      }
    default:
      return state
  }
}
