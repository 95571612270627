import React, { Component } from 'react'
import { Field } from 'react-final-form'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import TextAnimation from '../../../textAnimationComponent'
import './style.scss'
import ToolTip from '../../../tooltip'

const composeValidators = validators => value => {
  return validators.reduce(
    (error, validator) => error || validator(value),
    undefined
  )
}

class FormInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string,
    tooltips: PropTypes.array,
    hidden: PropTypes.bool,
    addClass: PropTypes.string,
    tag: PropTypes.string,
    mask: PropTypes.string
  }

  render() {
    const {
      name = '',
      type,
      placeholder = '',
      rules = undefined,
      label = '',
      value = undefined,
      tooltips = [],
      hidden = false,
      addClass = '',
      tag = 'input',
      mask = '',
      version = '',
      ...rest
    } = this.props
    let validationRules = rules
    if (Array.isArray(rules)) {
      validationRules = composeValidators(rules)
    }

    const Tag = tag === 'input' ? 'input' : 'textarea'

    return (
      <Field name={name} validate={validationRules} value={value}>
        {({ input, meta }) => (
          <div
            className={
              'form-input ' +
              (version && version === '2' ? 'form-inputv2 ' : '') +
              (meta.error && meta.touched ? 'error ' : '') +
              (type && (type === 'radio' || type === 'checkbox')
                ? type + ' '
                : '') +
              (hidden ? ' hidden ' : '') +
              (addClass ? addClass : '')
            }
          >
            <ToolTip tooltips={tooltips}>
              {mask === '' ? (
                tag === 'textarea' ? (
                  <div className='form-input__textarea-wrapper'>
                    <Tag
                      {...input}
                      type={tag === 'input' ? (type ? type : 'text') : null}
                      placeholder={placeholder}
                      id={name + value}
                      {...rest}
                      className='form-input__input'
                    />
                  </div>
                ) : (
                  <Tag
                    {...input}
                    type={tag === 'input' ? (type ? type : 'text') : null}
                    placeholder={placeholder}
                    id={name + value}
                    {...rest}
                    className='form-input__input'
                  />
                )
              ) : (
                <InputMask
                  {...input}
                  mask={mask}
                  maskChar='_'
                  type={type ? type : 'text'}
                  placeholder={placeholder}
                  id={name + value}
                  {...rest}
                  className='form-input__input'
                />
              )}
              {type && (type === 'radio' || type === 'checkbox') ? (
                <label htmlFor={name + value}>{label}</label>
              ) : (
                undefined
              )}
            </ToolTip>
            {type !== 'radio' &&
            type !== 'checkbox' &&
            meta.error &&
            meta.touched ? (
              <TextAnimation text={meta.error} className='form-input__error' />
            ) : (
              undefined
            )}
          </div>
        )}
      </Field>
    )
  }
}

export default FormInput
