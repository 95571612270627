import {
  GET_ALL_HELPTYPES_SUCCESS,
  GET_FUND_CITIES_SUCCESS,
  GET_FUNDS_REQUEST,
  GET_FUNDS_SUCCESS,
  POST_HELP_REQUEST_FAILURE,
  POST_HELP_REQUEST_REQUEST,
  POST_HELP_REQUEST_SUCCESS,
  SET_CURRENT_CITY_SUCCESS,
  SET_CURRENT_FUND_SUCCESS
} from '../actions/funds'

const initialState = {
  funds: [],
  help: ['medicines', 'food', 'transport', 'guardianship'],
  currentCity: {
    value: 'All',
    label: 'Выберите город'
  },
  currentFund: undefined,
  currentHelp: undefined
}

export const funds = (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case POST_HELP_REQUEST_REQUEST:
      return { ...state, helpRequest: true }
    case POST_HELP_REQUEST_SUCCESS:
    case POST_HELP_REQUEST_FAILURE:
      return { ...state, helpRequest: false }
    case GET_ALL_HELPTYPES_SUCCESS:
      return { ...state, allHelpTypes: action.payload }
    case GET_FUNDS_REQUEST:
      return { ...state, funds: [], currentHelp: action.meta.help }
    case GET_FUNDS_SUCCESS:
      return { ...state, funds: action.payload.results }
    case SET_CURRENT_FUND_SUCCESS:
      return { ...state, currentFund: action.payload.fund }
    case SET_CURRENT_CITY_SUCCESS:
      return { ...state, currentCity: action.payload.city }
    case GET_FUND_CITIES_SUCCESS:
      const defaultVariants = [
        {
          title: 'Выберите город',
          value: 'All'
        }
      ]
      return {
        ...state,
        cities: defaultVariants.concat([], action.payload)
      }
    default:
      return state
  }
}
