// Main Page

export const mainPageFindCatBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'mainPage_find_cat_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const mainPageFindDogBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'mainPage_find_dog_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const mainPageAllPetsBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'mainPage_all_pets_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const mainPagePetInfoClickEvent = url => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'mainPage_pet_info_click',
    ParameterName1: 'pet_info_URL',
    ParameterValue1: url // передавать URL страницы, на которую ведет блок
  })
}
export const logoBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'logo_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const loginAndRegistrationBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'login_and_registration_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const signUpClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'sign_up',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const loginClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'login',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const restorePasswordBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'restore_password_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const menuFindPetBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'menu_find_pet_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const menuAboutProjectBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'menu_about_project_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const menuQandABtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'menu_QandA_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const menuArticlesBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'menu_articles_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const mainPageAllStoriesBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'mainPage_all_stories_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const mainPageSheltersBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'mainPage_shelters_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const mainPagePartnersBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'mainPage_partners_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const mainPageShelterInfoBtnClickEvent = url => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'mainPage_shelter_info_button',
    ParameterName1: 'shelter_URL',
    ParameterValue1: url // передавать URL страницы, на которую ведет блок
  })
}
export const mainPagePartnerInfoBtnClickEvent = url => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'mainPage_partner_info_button',
    ParameterName1: 'partner_URL',
    ParameterValue1: url // передавать URL страницы, на которую ведет блок
  })
}
export const findPetCategoryBtnClickEvent = category => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'findPet_category_button',
    ParameterName1: 'category_name',
    ParameterValue1: category // передавать название категории, по которой был клик, например, «Рыжие котята»
  })
}
export const findPetFilterBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'findPet_filter_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const findPetInfoBtnClickEvent = url => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'findPet_pet_info_click',
    ParameterName1: 'pet_info_URL',
    ParameterValue1: url // передавать URL страницы, на которую ведет блок
  })
}
/////////////////////////////////////////////////////////////////////////////

// About Page

export const aboutPageLearnMoreBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'aboutPage_learn_more_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const aboutPageAllStoriesBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'aboutPage_all_stories_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const aboutPageAllPetsBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'aboutPage_all_pets_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const aboutPagePetInfoClickEvent = url => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'aboutPage_pet_info_click',
    ParameterName1: 'pet_info_URL',
    ParameterValue1: url // передавать URL страницы, на которую ведет блок
  })
}
export const aboutPageMoreAboutBrandBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'aboutPage_more_about_brand_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
/////////////////////////////////////////////////////////////

// Story Page

export const storyPageStoryMoreBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'storyPage_story_more_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const storyPageAllStoriesBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'storyPage_all_stories_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
////////////////////////////////////////////////////////////////

// Articles Page

export const articlesPageArticleClickEvent = url => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'articlesPage_article_click',
    ParameterName1: 'article_URL',
    ParameterValue1: url // передавать URLстраницы, на которую ведет блок
  })
}
///////////////////////////////////////////////////////////////

// School Page
export const schoolPageChangeTopicMedClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'schoolPage_change_topic_click',
    ParameterName1: 'topic_name',
    ParameterValue1: 'med_care'
  })
}

export const schoolPageChangeTopicSocClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'schoolPage_change_topic_click',
    ParameterName1: 'topic_name',
    ParameterValue1: 'socialization'
  })
}
export const schoolPageChangeTopicResClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'schoolPage_change_topic_click',
    ParameterName1: 'topic_name',
    ParameterValue1: 'responsibility'
  })
}
export const schoolPageStartTestClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'schoolPage_start_test_click',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
//////////////////////////////////////////////////////////////

// Partners Page
export const partnersPageBecomePartnerBtnClickEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'partnersPage_become_partner_button',
    ParameterName1: 'click',
    ParameterValue1: 'click'
  })
}
export const partnersPagePaArtnerBtnClickEvent = url => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'partnersPage_partner_button',
    ParameterName1: 'partner_URL',
    ParameterValue1: url // передавать URL страницы, на которую ведет блок
  })
}
export const partnerPageSendFormEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'partnerPage_send_form',
    ParameterName1: 'status',
    ParameterValue1: 'send'
  })
}
//////////////////////////////////////////////////////////////////////

// Feedback Page
export const feedbackSendFormEvent = () => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'feedback_send_form',
    ParameterName1: 'status',
    ParameterValue1: 'success'
  })
}

// Products Page
export const productsPageProductTypeEvent = productName => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'productsPage_product_type_button',
    ParameterName1: 'product_name',
    ParameterValue1: productName
  })
}

export const productsPageProductMoreEvent = productURL => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'productsPage_product_more_button',
    ParameterName1: 'product_URL',
    ParameterValue1: productURL
  })
}

// Pets Page
export const petsPageButtonClick = (buttonName, petUrl) => {
  window.dataLayer.push({
    event: 'GA4event',
    eventName: 'petPage_button_click',
    ParameterName1: 'button_name',
    ParameterValue1: buttonName,
    ParameterName2: 'pet_info_URL',
    ParameterValue2: petUrl
  })
}
