import { call, put, select } from 'redux-saga/effects'
import { pets } from '../reducers/selectors'
import { getOtherPetsActionSaga } from '../actions/pets'

function otherPetsSearch(petsSelector, length_d) {
  const param_length = (petsSelector.slice(0, petsSelector.length - length_d))
  const param_concat = (param_length.join('&'))
  return fetch(`/api/pets-others?page=0&items_per_page=20${param_concat}&_format=json`,
      {
        params: {},
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }
  )
      .then(response => (response.json()))
      .catch(error => (error))
}

function* otherPetsAsync(params = []) {

  const petsSelector = yield select(pets)

  const weightArray = ['pet_city', 'pet_type', 'pet_sex', 'pet_age[]', 'pet_size[]', 'pet_temp', 'pet_hair', 'pet_color[]']

  const array_sort = petsSelector.currentFiltersPreset.split('&').map((el) => el.split('='))

  const sortArray = array_sort.sort(function(a, b) {
    return weightArray.indexOf(a[0]) - weightArray.indexOf(b[0])
  }).map((el) => el.join('='))

  function* recOtherPets(sortArray, length_d = 2) {
    const response = yield call(() => otherPetsSearch(sortArray, length_d))
    if (response.results.length < 3) {
      yield* recOtherPets(sortArray, length_d + 1)
    } else {
      yield put(getOtherPetsActionSaga(response.results))
    }
  }

  yield* recOtherPets(sortArray)
}

export default otherPetsAsync
