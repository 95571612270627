import { apiAction } from './apiAction'

export const GET_PET_FONDS_REQUEST = 'GET_PET_FONDS_REQUEST'
export const GET_PET_FONDS_SUCCESS = 'GET_PET_FONDS_SUCCESS'
export const GET_PET_FONDS_FAILURE = 'GET_PET_FONDS_FAILURE'

export const getPetFonds = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/fondlist',
    method: 'GET',
    types: [
      GET_PET_FONDS_REQUEST,
      GET_PET_FONDS_SUCCESS,
      GET_PET_FONDS_FAILURE
    ],
    noAuthorization: true
  })
}
