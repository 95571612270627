import { NEED_SEND_PET_ACTION, TOGGLE_LOGIN_POPUP } from '../actions'

import { GET_ME_SUCCESS } from '../actions/user'

const initialState = {
  opened: false,
  tab: 'login',
  needSendPetAction: false
}

export const loginPopup = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case TOGGLE_LOGIN_POPUP:
      return {
        ...state,
        opened: !action.payload.isOpened,
        tab: action.payload.tab,
        needSendPetAction:
          action.payload.isOpened && state.needSendPetAction
            ? false
            : state.needSendPetAction
      }
    case GET_ME_SUCCESS:
      return { ...state, opened: false }
    case NEED_SEND_PET_ACTION:
      return { ...state, needSendPetAction: payload }
    default:
      return state
  }
}
