import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Wrapper from '../wrapper'
import './style.scss'

class Footer extends Component {
  render() {
    const rel = { rel: 'nofollow' }

    return (
      <footer className='footer'>
        <Wrapper>
          <img
            src='/images/logo_slogan.png'
            alt='Purina логотип'
            className='footer__logo'
          />
          <nav className='footer-nav'>
            <Link className='footer-nav__item' to={'/pets'} {...rel}>
              Питомцы
            </Link>
            <Link className='footer-nav__item' to={'/about'} {...rel}>
              О проекте
            </Link>
            <Link className='footer-nav__item' to={'/partners'} {...rel}>
              Партнеры
            </Link>
            {/*<Link className='footer-nav__item' to={'/volunteering'} {...rel}>*/}
            {/*  Помочь приютам*/}
            {/*</Link>*/}
            <a
              className='footer-nav__item'
              href='/rules.pdf?v=1'
              target='_blank'
              rel='nofollow noopener noreferrer'
            >
              Правила проекта
            </a>
            <Link className='footer-nav__item' to={'/FAQ'} {...rel}>
              Часто задаваемые вопросы
            </Link>
            <Link className='footer-nav__item' to={'/feedback'} {...rel}>
              Обратная связь
            </Link>
            <Link
              className='footer-nav__item'
              to={'/pet-search-sitemap'}
              {...rel}
            >
              Карта сайта
            </Link>

            <a
              className='footer-nav__item'
              href='https://www.purina.ru/data-policy'
              target='_blank'
              rel='nofollow noopener noreferrer'
            >
              Конфиденциальность
            </a>
          </nav>
          <div className='phone-block'>
            <div className='phone-block__phone'>
              <span className='phone-block__word'>Горячая линия:</span>
              <a className='phone-block__tel-link' href='tel:88002008900'>
                8 800 200 8 900
                {this.props.location === '/about'
                  ? '. Россия, г. Москва, Павелецкая площадь, д.2, стр. 1'
                  : null}
              </a>
            </div>
          </div>
          <div className="footer__disclaimer">
            {`Purina ${new Date().getFullYear()}® Владелец товарных знаков: Société des Produits Nestlé S.A.`}
          </div>
        </Wrapper>
      </footer>
    )
  }
}

export default Footer
