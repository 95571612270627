import { apiAction } from './apiAction'
import objectToFormData from 'object-to-formdata'

export const GET_FUNDS_REQUEST = 'GET_FUNDS_REQUEST'
export const GET_FUNDS_SUCCESS = 'GET_FUNDS_SUCCESS'
export const GET_FUNDS_FAILURE = 'GET_FUNDS_FAILURE'

export const getFundsAction = (param, value, help = undefined) => (
  dispatch,
  getState
) => {
  apiAction(dispatch, getState, {
    endpoint:
      '/api/volunteering' + (param && value ? '?' + param + '=' + value : ''),
    method: 'GET',
    types: [
      {
        type: GET_FUNDS_REQUEST,
        meta: { help: help }
      },
      GET_FUNDS_SUCCESS,
      GET_FUNDS_FAILURE
    ],
    noAuthorization: true
  })
}

export const SET_CURRENT_FUND_SUCCESS = 'SET_CURRENT_FUND_SUCCESS'

export const setCurrentFund = fund => ({
  type: SET_CURRENT_FUND_SUCCESS,
  payload: {
    fund: fund
  }
})

export const SET_CURRENT_CITY_SUCCESS = 'SET_CURRENT_CITY_SUCCESS'

export const setCurrentCity = city => ({
  type: SET_CURRENT_CITY_SUCCESS,
  payload: {
    city: city
  }
})

export const SET_CURRENT_SHOP_SUCCESS = 'SET_CURRENT_SHOP_SUCCESS'

export const setCurrentShop = shop => ({
  type: SET_CURRENT_SHOP_SUCCESS,
  payload: {
    shop: shop
  }
})

export const GET_ALL_HELPTYPES_REQUEST = 'GET_ALL_HELPTYPES_REQUEST'
export const GET_ALL_HELPTYPES_SUCCESS = 'GET_ALL_HELPTYPES_SUCCESS'
export const GET_ALL_HELPTYPES_FAILURE = 'GET_ALL_HELPTYPES_FAILURE'

export const getAllHelptypes = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/helptype',
    method: 'GET',
    types: [
      GET_ALL_HELPTYPES_REQUEST,
      GET_ALL_HELPTYPES_SUCCESS,
      GET_ALL_HELPTYPES_FAILURE
    ],
    noAuthorization: true
  })
}

export const POST_HELP_REQUEST_REQUEST = 'POST_HELP_REQUEST_REQUEST'
export const POST_HELP_REQUEST_SUCCESS = 'POST_HELP_REQUEST_SUCCESS'
export const POST_HELP_REQUEST_FAILURE = 'POST_HELP_REQUEST_FAILURE'

export const sendHelpRequestAction = helpJson => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/volunteering/contacts',
    method: 'POST',
    types: [
      POST_HELP_REQUEST_REQUEST,
      POST_HELP_REQUEST_SUCCESS,
      POST_HELP_REQUEST_FAILURE
    ],
    body: objectToFormData(helpJson),
    noAuthorization: true
  })
}

export const GET_FUND_CITIES_REQUEST = 'GET_FUND_CITIES_REQUEST'
export const GET_FUND_CITIES_SUCCESS = 'GET_FUND_CITIES_SUCCESS'
export const GET_FUND_CITIES_FAILURE = 'GET_FUND_CITIES_FAILURE'

export const getFundCities = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/fundcities',
    method: 'GET',
    types: [
      GET_FUND_CITIES_REQUEST,
      GET_FUND_CITIES_SUCCESS,
      GET_FUND_CITIES_FAILURE
    ],
    noAuthorization: true
  })
}
