import React, { Component } from 'react'
import './style.scss'

class ScrollTop extends Component {
  state = {
    isVisible: false,
    screenHeight: window.screen.height / 2
  };

  componentDidMount() {
    window.addEventListener('scroll', this.showBtn);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.showBtn);
  }

  showBtn = () => {
    if (window.scrollY >= this.state.screenHeight  && !this.state.isVisible) {
      this.setState({ isVisible: true });
    } else if (window.scrollY < this.state.screenHeight && this.state.isVisible) {
      this.setState({ isVisible: false });
    }
  };

  scrollToUp = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  render() {
    return (
      <button
          className='scroll-top'
          style={{ display: this.state.isVisible ? "block" : "none" }}
          onClick={this.scrollToUp}
      >
          <img
            src='/images/scroll-top.svg'
            alt='Кнопка вверх'
          />
      </button>
    )
  }
}

export default ScrollTop
