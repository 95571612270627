import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.scss'

import ConfirmForm from '../../forms/confirmForm'

class PopupInfo extends Component {
  static propTypes = {
    opened: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string,
    text: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    submitCodeAction: PropTypes.func.isRequired,
    toggleLoginPopup: PropTypes.func.isRequired
  }

  toggleLoginPopup() {
    const { toggleLoginPopup, loginPopupIsOpened } = this.props
    toggleLoginPopup(loginPopupIsOpened)
    this.props.onClose()
  }

  handleClick() {
    const { onClose, toggleLoginPopup, openTypeOfForm, logoutAction } = this.props
    openTypeOfForm === 'login' && logoutAction() && toggleLoginPopup()
    onClose()
  }

  render() {
    const {
      message = '',
      type = 'success',
      phone,
      text = '',
      opened = false,
      submitCodeAction,
      customCloseButtonText
    } = this.props

    return type === 'confirm' ? (
      <div className={'custom-popup ' + (opened ? 'opened ' : ' ') + type}>
        <div className='custom-popup__wrapper'>
          <ConfirmForm
            submitAction={submitCodeAction}
            phone={phone}
            text={text}
          />
          <div
            className='custom-popup__close-btn'
            onClick={this.toggleLoginPopup.bind(this)}
          />
          {this.props.children}
        </div>
      </div>
    ) : (
      <div className={'info-popup ' + (opened ? 'opened ' : ' ') + type}>
        <div className='info-popup__wrapper'>
          {message}
          { !customCloseButtonText ? (
            <div className='info-popup__close-btn' onClick={this.handleClick.bind(this)} />
          ) : (
            <div 
              className='info-popup__custom-close-btn btn btn-blue no-icon' 
              onClick={this.handleClick.bind(this)}
            >
              {customCloseButtonText}
            </div>
          )}
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default PopupInfo
