import { apiAction } from './apiAction'

export const GET_PETS_REQUEST = 'GET_PETS_REQUEST'
export const GET_PETS_SUCCESS = 'GET_PETS_SUCCESS'
export const GET_PETS_FAILURE = 'GET_PETS_FAILURE'
export const GET_PETS_FAILURE_FAVORITES = 'GET_PETS_FAILURE_FAVORITES'

export const getPetsAction = (filters = '', items_per_page = 12, page = 0) => (
  dispatch,
  getState
) => {
  apiAction(dispatch, getState, {
    endpoint:
      '/api/favorites?' +
      encodeURI(filters) +
      (items_per_page ? '&items_per_page=' + items_per_page : '') +
      (page ? '&page=' + page : '&page=0'),
    method: 'GET',
    types: [GET_PETS_REQUEST, GET_PETS_SUCCESS, GET_PETS_FAILURE_FAVORITES],
    noAuthorization: false
  })
}

export const GET_OTHER_PETS_REQUEST = 'GET_OTHER_PETS_REQUEST'
export const GET_OTHER_PETS_SUCCESS = 'GET_OTHER_PETS_SUCCESS'
export const GET_OTHER_PETS_FAILURE = 'GET_OTHER_PETS_FAILURE'

export const getOtherPetsAction = (
  filters = '',
  items_per_page = 20,
  page = 0
) => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint:
      '/api/favorites?' +
      encodeURI(filters) +
      (items_per_page ? '&items_per_page=' + items_per_page : '') +
      (page ? '&page=' + page : '&page=0'),
    method: 'GET',
    types: [
      GET_OTHER_PETS_REQUEST,
      GET_OTHER_PETS_SUCCESS,
      GET_PETS_FAILURE_FAVORITES
    ],
    noAuthorization: true
  })
}

export const getMorePetsAction = (
  filters = '',
  items_per_page = 12,
  page = 0
) => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint:
      '/api/favorites?' +
      encodeURI(filters) +
      (items_per_page ? '&items_per_page=' + items_per_page : '') +
      (page ? '&page=' + page : '&page=0'),
    method: 'GET',
    types: [GET_PETS_REQUEST, GET_PETS_SUCCESS, GET_PETS_FAILURE_FAVORITES],
    noAuthorization: true
  })
}

export const testUid = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/petnode/10389',
    method: 'GET',
    types: [GET_PETS_REQUEST, GET_PETS_SUCCESS, GET_PETS_FAILURE_FAVORITES],
    noAuthorization: false
  })
}

export const PARSE_URL_SUCCESS = 'PARSE_URL_SUCCESS'

export const parseURL = url => {
  return {
    type: PARSE_URL_SUCCESS,
    payload: {
      url
    }
  }
}

export const CLEAR_CURRENT_PET = 'CLEAR_CURRENT_PET'

export const clearCurrentPet = () => ({
  type: CLEAR_CURRENT_PET
})

export const REMEMBER_FILTERS_SUCCESS = 'REMEMBER_FILTERS_SUCCESS'

export const rememberFiltersSet = (filters = '') => ({
  type: REMEMBER_FILTERS_SUCCESS,
  payload: filters
})

export const GET_PETS_BY_TYPE_REQUEST = 'GET_PETS_BY_TYPE_REQUEST'
export const GET_PETS_BY_TYPE_SUCCESS = 'GET_PETS_BY_TYPE_SUCCESS'
export const GET_PETS_BY_TYPE_FAILURE = 'GET_PETS_BY_TYPE_FAILURE'

export const getPetsByTypeAction = (type = 'active') => (
  dispatch,
  getState
) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/favorites/' + type,
    method: 'GET',
    types: [
      GET_PETS_BY_TYPE_REQUEST,
      GET_PETS_BY_TYPE_SUCCESS,
      GET_PETS_BY_TYPE_FAILURE
    ],
    noAuthorization: true
  })
}

export const GET_PET_REQUEST = 'GET_PET_REQUEST'
export const GET_PET_SUCCESS = 'GET_PET_SUCCESS'
export const GET_PET_FAILURE = 'GET_PET_FAILURE'
export const GET_PET_FAILURE_FAVORITES = 'GET_PET_FAILURE_FAVORITES'

export const getPetAction = id => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/node/' + id,
    method: 'GET',
    types: [GET_PET_REQUEST, GET_PET_SUCCESS, GET_PET_FAILURE_FAVORITES],
    noAuthorization: true
  })
}
