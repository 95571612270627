import { put } from 'redux-saga/effects'
import { getTrainingInfoAction } from '../actions/training'
import { getMaterialShortAction } from '../actions/library'

function* trainingSaga() {
  yield put(getTrainingInfoAction())
  yield put(getMaterialShortAction())
}

export default trainingSaga
