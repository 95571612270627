import {
  CLOSE_STORIES_POPUP,
  GET_BLOCKS_SUCCESS,
  GET_STORIES_FAILURE,
  GET_STORIES_SUCCESS,
  SHOW_STORY_IN_POPUP
} from '../actions/story'

const STORIES = []

const initialState = {
  stories: STORIES,
  blocks: [],
  currentStory: null,
  isStoriesPopupOpened: false
}

export const story = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case GET_STORIES_SUCCESS:
      return {
        ...state,
        stories: action.payload
      }
    case GET_BLOCKS_SUCCESS:
      return {
        ...state,
        blocks: action.payload
      }
    case GET_STORIES_FAILURE:
      return {
        ...state,
        stories: STORIES
      }
    case SHOW_STORY_IN_POPUP:
      return {
        ...state,
        currentStory: action.payload.currentStory,
        isStoriesPopupOpened: true
      }
    case CLOSE_STORIES_POPUP:
      return {
        ...state,
        isStoriesPopupOpened: false
      }
    default:
      return state
  }
}
