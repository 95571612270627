import { put, select, take, takeEvery } from 'redux-saga/effects'

import { getMeAction, LOGIN_SUCCESS, loginAction } from '../actions/user'
import { regWebStat } from '../utils/weboramaAction'

function* watchConfirmPhone() {
  yield takeEvery('PHONECONFIRM_SUCCESS', phoneConfirmAcync)
}

const getUserInfo = state => state.user

function* phoneConfirmAcync() {
  const userInfo = yield select(getUserInfo)
  const sendingInfo = {
    username: userInfo.username,
    password: userInfo.password
  }
  yield put(loginAction(sendingInfo))
  yield take(LOGIN_SUCCESS)
  yield put(getMeAction())
  yield regWebStat()
}

export default watchConfirmPhone
