import { routerReducer as routing } from 'react-router-redux'
import { combineReducers } from 'redux'
import { config } from './config'
import { pets } from './pets'
import { favorites } from './favorites'
import { events } from './events'
import { partners } from './partners'
import { user } from './user'
import { training } from './training'
import { library } from './library'
import { faq } from './faq'
import { story } from './story'
import { infoPopup } from './infoPopup'
import { loginPopup } from './loginPopup'
import { test } from './test'
import { funds } from './funds'
import { feedback } from './feedback'
import { organizations } from './organizations'
import { articles } from './articles'
import { ourArticles } from './ourarticles'
import { lk } from './lk'
import { error } from './error'

const rootReducer = combineReducers({
  routing,
  pets,
  favorites,
  events,
  partners,
  config,
  user,
  training,
  library,
  story,
  infoPopup,
  loginPopup,
  test,
  faq,
  funds,
  feedback,
  organizations,
  articles,
  ourArticles,
  lk,
  error
})

export default rootReducer
