import React, { Component } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'
import { masked, required } from '../validationRules'
import FormInput from '../formComponents/input'
import Button from '../../button'
import { loginClickEvent } from "../../../utils/ga4-events"
import '../style.scss'
import './style.scss'

class LoginForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    linkAction: PropTypes.func.isRequired,
    active: PropTypes.bool
  }

  submitFormHandler() {
    const { submitAction, registrationConfirm } = this.props
    const sendingValues = {
      username: this.state.submittedValues.username,
      password: this.state.submittedValues.password
    }
    registrationConfirm(this.state.submittedValues ? { ...sendingValues } : '')
    submitAction(this.state.submittedValues)
    loginClickEvent();
  }

  render() {
    const {
      linkAction = () => {
      }, active = true
    } = this.props
    return (
      <div className={'form login-form ' + (active ? 'active' : 'inactive')}>
        <span className='form-title'>Авторизация</span>
        <Form
          onSubmit={submittedValues => {
            this.setState(
              { submittedValues },
              this.submitFormHandler.bind(this)
            )
          }}
          validate={() => {
          }}
          render={({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <div className='form__field form__field_m-50'>
                <FormInput
                  name={'username'}
                  placeholder={'Телефон'}
                  mask='+79999999999'
                  rules={[required, masked]}
                />
              </div>
              <div className='form__field form__field_m-50'>
                <FormInput
                  name={'password'}
                  placeholder={'Пароль'}
                  rules={[required]}
                  type={'password'}
                />
              </div>
              <div className='form__field form__field_m-100 form-text'>
                <p>
                  *Если вы уже зарегистрированы на сайте, обратите внимание, что
                  для авторизации необходимо ввести номер телефона, указанный
                  вами при регистрации
                </p>
              </div>
              <div className='form__btn-wrapper'>
                <button type='submit'>
                  <Button text='ВОЙТИ' type='submit' />
                </button>
                <div className='form__link' onClick={linkAction}>
                  Забыли пароль?
                </div>
              </div>
            </form>
          )}
        />
      </div>
    )
  }
}

export default LoginForm
