import { apiAction } from './apiAction'
import { RSAA } from 'redux-api-middleware'

export const GET_MATERIAL_SHORT_REQUEST = 'GET_MATERIAL_SHORT_REQUEST'
export const GET_MATERIAL_SHORT_SUCCESS = 'GET_MATERIAL_SHORT_SUCCESS'
export const GET_MATERIAL_SHORT_FAILURE = 'GET_MATERIAL_SHORT_FAILURE'

export const getMaterialShortAction = (items_per_page = 3, page = 0) => (
  dispatch,
  getState
) => {
  apiAction(dispatch, getState, {
    endpoint:
      '/api/learning/materials?' +
      (items_per_page ? '&items_per_page=' + items_per_page : '') +
      (page ? '&page=' + page : '&page=0'),
    method: 'GET',
    types: [
      GET_MATERIAL_SHORT_REQUEST,
      GET_MATERIAL_SHORT_SUCCESS,
      GET_MATERIAL_SHORT_FAILURE
    ],
    noAuthorization: true
  })
}

export const GET_ARTICLES_REQUEST = 'GET_ARTICLES_REQUEST'
export const GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS'

export const getArticles = () => {
  return {
    [RSAA]: {
      endpoint: 'api/articles',
      method: 'GET',
      types: [GET_ARTICLES_REQUEST, GET_ARTICLES_SUCCESS]
    }
  }
}

export const SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICLE'

export const setCurrentArticle = id => ({
  type: SET_CURRENT_ARTICLE,
  payload: {
    currentArticle: id
  }
})
