import { SEND_FEEDBACK_FAILURE, SEND_FEEDBACK_REQUEST, SEND_FEEDBACK_SUCCESS } from '../actions/feedback'

const initialState = {}

export const feedback = (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case SEND_FEEDBACK_REQUEST:
      return { ...state, feedbackRequest: true }
    case SEND_FEEDBACK_SUCCESS:
    case SEND_FEEDBACK_FAILURE:
      return { ...state, feedbackRequest: false }
    default:
      return state
  }
}
