const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  } else {
    return null
  }
}

const markTargetAction = () => {
  let didTargetAction = getCookie('did_target_action')
  if (didTargetAction == null) {
    didTargetAction = 1
    document.cookie = 'did_target_action=' + didTargetAction + ' ; path=/'
  }
}

export { getCookie, markTargetAction }
