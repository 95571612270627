import { apiAction } from './apiAction'
import objectToFormData from 'object-to-formdata'

export const GET_PARTNERS_REQUEST = 'GET_PARTNERS_REQUEST'
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS'
export const GET_PARTNERS_FAILURE = 'GET_PARTNERS_FAILURE'

export const getPartnersAction = (type = 'fonds') => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/partners/' + type,
    method: 'GET',
    types: [GET_PARTNERS_REQUEST, GET_PARTNERS_SUCCESS, GET_PARTNERS_FAILURE],
    noAuthorization: true
  })
}

export const GET_PARTNER_REQUEST = 'GET_PARTNER_REQUEST'
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS'
export const GET_PARTNER_FAILURE = 'GET_PARTNER_FAILURE'

export const getPartnerAction = id => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/fonds/' + id,
    method: 'GET',
    types: [GET_PARTNER_REQUEST, GET_PARTNER_SUCCESS, GET_PARTNER_FAILURE],
    noAuthorization: true
  })
}

export const GET_SHOP_REQUEST = 'GET_SHOP_REQUEST'
export const GET_SHOP_SUCCESS = 'GET_SHOP_SUCCESS'
export const GET_SHOP_FAILURE = 'GET_SHOP_FAILURE'

export const getShopAction = id => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/partners/' + id,
    method: 'GET',
    types: [GET_SHOP_REQUEST, GET_SHOP_SUCCESS, GET_SHOP_FAILURE],
    noAuthorization: true
  })
}

export const SEND_PARTNERS_REQUEST = 'SEND_PARTNERS_REQUEST'
export const SEND_PARTNERS_SUCCESS = 'SEND_PARTNERS_SUCCESS'
export const SEND_PARTNERS_FAILURE = 'SEND_PARTNERS_FAILURE'

export const sendPartnersRequest = partnersJson => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/partners/request',
    method: 'POST',
    types: [
      SEND_PARTNERS_REQUEST,
      SEND_PARTNERS_SUCCESS,
      SEND_PARTNERS_FAILURE
    ],
    body: objectToFormData(partnersJson),
    noAuthorization: true
  })
}

export const SET_CURRENT_PARTNER_SUCCESS = 'SET_CURRENT_PARTNER_SUCCESS'

export const setCurrentPartner = partner => ({
  type: SET_CURRENT_PARTNER_SUCCESS,
  payload: {
    partner
  }
})
