import { put } from 'redux-saga/effects'
import { getAllHelptypes, getFundCities, getFundsAction } from '../actions/funds'
import { getPartnersAction } from '../actions/partners'

function* volunteeringSaga() {
  yield put(getFundCities())
  yield put(getAllHelptypes())
  yield put(getFundsAction())
  yield put(getPartnersAction('fonds'))
}

export default volunteeringSaga
