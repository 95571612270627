import {
  GET_CURRENT_ARTICLE_FAILURE,
  GET_CURRENT_ARTICLE_REQUEST,
  GET_CURRENT_ARTICLE_SUCCESS,
  GET_OUR_ARTICLESLIST_FAILURE,
  GET_OUR_ARTICLESLIST_REQUEST,
  GET_OUR_ARTICLESLIST_SUCCESS,
  SET_SEARCH_ARTICLE_TITLE
} from '../actions/articles'

const initialState = {
  list: [],
  filterResult: [],
  currentArticle: {},
  success: false,
  error: false,
  title: null
}

export const ourArticles = (state = initialState, action) => {
  const { type, payload, meta } = action

  switch (type) {
    case GET_OUR_ARTICLESLIST_REQUEST:
    case GET_CURRENT_ARTICLE_REQUEST:
      return {
        ...state,
        success: false,
        error: false
      }
    case GET_OUR_ARTICLESLIST_SUCCESS:
      let result = {}
      if (meta.searching) {
        result = {
          ...state,
          filterResult: payload,
          success: true
        }
      } else {
        result = {
          ...state,
          list: payload,
          success: true
        }
      }
      return result
    case GET_CURRENT_ARTICLE_SUCCESS:
      return {
        ...state,
        currentArticle: payload.length ? payload[0] : payload
      }
    case SET_SEARCH_ARTICLE_TITLE:
      return {
        ...state,
        title: meta.value
      }
    case GET_OUR_ARTICLESLIST_FAILURE:
    case GET_CURRENT_ARTICLE_FAILURE:
      return { ...state, error: true }
    default:
      return state
  }
}
