import { race, take } from 'redux-saga/effects'

function* navSaga() {
  while (true) {
    const { locationChange } = yield race({
      locationChange: take('@@router/LOCATION_CHANGE')
    })
    try {
      const scroll = require('react-scroll')
      if (
        locationChange.payload.pathname.search(/\/library\/*/) === -1 &&
        locationChange.payload.pathname.search(/\/our-articles\//) === -1 &&
        locationChange.payload.pathname.search(/\/pets\/*/) === -1
      ) {
        scroll.animateScroll.scrollToTop()
      }
    } catch (e) {
    }
  }
}

export default navSaga
