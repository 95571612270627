import { put } from 'redux-saga/effects'
import { getShopAction } from '../actions/partners'
import { getAllHelptypes, getFundsAction } from '../actions/funds'

function* shopSaga(params) {
  yield put(getShopAction(params.id))
  yield put(getAllHelptypes())
  yield put(getFundsAction())
}

export default shopSaga
