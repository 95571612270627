import { put, take } from 'redux-saga/effects'

import { getMeAction } from '../actions/user'

import { COMPLETE_TEST_SUCCESS } from '../actions/test'

import { testWebStat } from '../utils/weboramaAction'

function* testSaga() {
  while (true) {
    yield take(COMPLETE_TEST_SUCCESS)
    yield put(getMeAction())
    yield put(testWebStat())
  }
}

export default testSaga
