import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { resendSms } from '../../../actions/user'

import { confirmPassword, password, required } from '../validationRules'
import objectToFormData from 'object-to-formdata'
import FormInput from '../formComponents/input'
import Button from '../../button'
import ResendSms from '../../resendSms'
import { restorePasswordBtnClickEvent} from "../../../utils/ga4-events"

class SecondStep extends Component {
  static propTypes = {
    restoreCompleteAction: PropTypes.func.isRequired,
    active: PropTypes.bool
  }

  restoreFormHandler() {
    const { restoreCompleteAction } = this.props
    let formData = objectToFormData(this.state.submittedValues)
    formData.append('phone', this.props.phone)
    restoreCompleteAction(formData)
    restorePasswordBtnClickEvent()
  }

  render() {
    return (
      <Form
        onSubmit={submittedValues => {
          this.setState({ submittedValues }, this.restoreFormHandler.bind(this))
        }}
        validate={values => {
          const errors = {}
          if (
            values.password1 &&
            values.password2 &&
            confirmPassword(values.password1, values.password2)
          ) {
            errors.password2 = confirmPassword(
              values.password1,
              values.password2
            )
          }
          return Object.keys(errors).length ? errors : undefined
        }}
        render={({ handleSubmit, pristine, invalid, values }) => (
          <form onSubmit={handleSubmit}>
            <div className='form__field form__field_m-50'>
              <FormInput
                name={'password1'}
                placeholder={'Новый пароль'}
                rules={[required, password]}
                type='password'
              />
            </div>
            <div className='form__field form__field_m-50'>
              <FormInput
                name={'password2'}
                placeholder={'Повторите пароль'}
                rules={[required, password]}
                type='password'
              />
            </div>
            <div className='form__field form__field_m-50'>
              <FormInput
                name={'code'}
                placeholder={'Код из SMS'}
                rules={[required]}
              />
            </div>
            <div className='form__btn-wrapper'>
              <button type='submit'>
                <Button text='ВОССТАНОВИТЬ ПАРОЛЬ' type='submit' />
              </button>
            </div>
            <div className='form__field'>
              <ResendSms sendFunc={this.props.onResendSms} />
            </div>
          </form>
        )}
      />
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onResendSms: () => dispatch(resendSms())
  }
}

export default connect(null, mapDispatchToProps)(SecondStep)
