import { all, put, take } from 'redux-saga/effects'
import { getCurrentArticle, getRandomArticles, getCurrentArticleSimilar } from '../actions/articles'
import { GET_CURRENT_ARTICLE_SUCCESS } from '../actions/articles'
import { getOtherPetsAction } from '../actions/pets'

function* articleSaga(params) {
  yield put(getCurrentArticle(params.path))
  yield put(getRandomArticles(params.path));
  yield put(getCurrentArticleSimilar(params.path))
  const { success } = yield all({
    success: take(GET_CURRENT_ARTICLE_SUCCESS)
  })

  if (success) {
    const { field_pet_type: value } = success.payload[0];
    const petType = value.length && value[0].value;
    if (petType) {
      const query = [
        `pet_type=${petType}`
      ]
      yield put(getOtherPetsAction(query.filter(Boolean).join('&')))
    }
  }
}

export default articleSaga
