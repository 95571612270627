import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import Popup from '../index'

const PopupLive = ({
                     isLiveOpened = false, closeLivePopup = () => {
  }
                   }) => {
  return (
    <Popup opened={isLiveOpened} closeBtnFunc={closeLivePopup}>
      <div className='popup-live'>
        <div className='popup-live__content'>
          <div className='popup-live__icon' />
          <div className='popup-live__title title_2'>
            <span className='popup-live__live'>LIVE</span> трансляции из приютов
          </div>
          <div className='section__text popup-live__text'>
            Смотрите записи YouTube-трансляций с&nbsp;собаками и&nbsp;кошками
            из&nbsp;приютов. Узнайте питомцев лучше и&nbsp;заполните заявку для
            очного знакомства на&nbsp;сайте &laquo;Друг для друга&raquo;. Ссылки
            на&nbsp;анкеты всех питомцев-героев трансляций вы&nbsp;найдете
            в&nbsp;описании эфиров.
          </div>
          <a
            href={
              'https://www.youtube.com/playlist?list=PLwVNYnBQXCZRSideBYIxV5V5RXLmPOSKc'
            }
            className='btn btn-red no-icon popup-live__btn'
          >
            К ТРАНСЛЯЦИЯМ
          </a>
        </div>
      </div>
    </Popup>
  )
}

PopupLive.propTypes = {
  isLiveOpened: PropTypes.bool.isRequired,
  closeLivePopup: PropTypes.func.isRequired
}

export default PopupLive
