import { CLOSE_EVENTS_POPUP, GET_EVENTS_SUCCESS, SHOW_EVENT_IN_POPUP } from '../actions/events'

const initialState = {
  events: [],
  currentEvent: null,
  isEventsPopupOpened: false
}

export const events = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case GET_EVENTS_SUCCESS:
      if (Array.isArray(action.payload)) {
        return { ...state, events: action.payload }
      } else {
        return { ...state }
      }
    case SHOW_EVENT_IN_POPUP:
      return {
        ...state,
        currentEvent: action.payload.currentEvent,
        isEventsPopupOpened: true
      }
    case CLOSE_EVENTS_POPUP:
      return {
        ...state,
        isEventsPopupOpened: false
      }
    default:
      return state
  }
}
