import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { apiMiddleware } from 'redux-api-middleware'
import persistState from 'redux-localstorage'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import asyncAwait from 'redux-async-await'
import rootReducer from '../reducers'
import apiRoot from './apiRoot'

const configureStore = history => {
  const sagaMiddleware = createSagaMiddleware()
  const router = routerMiddleware(history)
  const store = createStore(
    rootReducer,
    compose(
      applyMiddleware(
        apiRoot,
        thunk,
        asyncAwait,
        apiMiddleware,
        sagaMiddleware,
        router
      ),
      persistState(['config', 'user'])
    )
  )

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }

  store.runSaga = sagaMiddleware.run

  return store
}

export default configureStore
