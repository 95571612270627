import {
  GET_CITIES_SUCCESS,
  GET_CONFIRM_FAILURE,
  GET_CONFIRM_REQUEST,
  GET_CONFIRM_SUCCESS,
  GET_ME_FAILURE,
  GET_ME_SUCCESS,
  GET_MY_PET_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTRATION_CONFIRM,
  REGISTRATION_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REMOVE_MY_PET_FAILURE,
  REMOVE_MY_PET_REQUEST,
  RESTORE_COMPLETE_FAILURE,
  RESTORE_COMPLETE_REQUEST,
  RESTORE_COMPLETE_SUCCESS,
  RESTORE_FAILURE,
  RESTORE_REQUEST,
  RESTORE_SUCCESS
} from '../actions/user'
import { EDIT_EMAIL_SUCCESS } from '../actions/lk'

const initialState = {}

export const user = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token:
          action.payload && action.payload.token
            ? action.payload.token
            : undefined
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        token: undefined,
        user: {},
        isAuth: false,
        loginRequest: false
      }
    case GET_ME_SUCCESS:
      window.dataLayer.push({
        event: 'uidlogin',
        userID: action.payload.uid[0].value
      })
      return {
        ...state,
        registrationRequest: false,
        restoreRequest: false,
        user: action.payload,
        isAuth: true,
        loginRequest: false,
        petIsRemoving: false,
        testComplete:
          action.payload.field_test_complete &&
          action.payload.field_test_complete[0]
            ? action.payload.field_test_complete[0].value
            : false,
        testTryLeft:
          action.payload.field_test_try_left &&
          action.payload.field_test_try_left[0]
            ? action.payload.field_test_try_left[0].value
            : false
      }
    case GET_ME_FAILURE:
      return {
        ...state,
        user: {},
        isAuth: false,
        loginRequest: false,
        registrationRequest: false,
        restoreRequest: false,
        petIsRemoving: false
      }
    case LOGOUT_SUCCESS:
      ;(function() {
      window.dataLayer.push({
        userID: undefined
      })
    })()
      return {}
    case LOGIN_REQUEST:
      return { ...state, user: {}, isAuth: false, loginRequest: true }
    case REGISTRATION_REQUEST:
      return { ...state, registrationRequest: true }
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationRequest: false,
        phoneConfirmed: action.payload.phone_confirmed
      }
    case REGISTRATION_CONFIRM:
      return {
        ...state,
        ...action.payload
      }
    case REGISTRATION_FAILURE:
      return { ...state, registrationRequest: false }
    case GET_CONFIRM_REQUEST:
      return { ...state, confirmRequest: true }
    case GET_CONFIRM_SUCCESS:
      return { ...state, confirmRequest: false }
    case GET_CITIES_SUCCESS:
      return { ...state, cities: action.payload }
    case GET_CONFIRM_FAILURE:
      return { ...state, confirmRequest: false }
    case RESTORE_REQUEST:
    case RESTORE_COMPLETE_REQUEST:
      return { ...state, restoreRequest: true, restoreNextStep: false }
    case RESTORE_SUCCESS:
      return {
        ...state,
        restoreRequest: false,
        restoreNextStep: true,
        username: action.meta.phone
      }
    case RESTORE_COMPLETE_SUCCESS:
      return { ...state, restoreRequest: false }
    case GET_MY_PET_SUCCESS:
      return { ...state, pets: action.payload }
    case RESTORE_FAILURE:
    case RESTORE_COMPLETE_FAILURE:
      return { ...state, restoreRequest: false }
    case REMOVE_MY_PET_REQUEST:
      return { ...state, petIsRemoving: true }
    case REMOVE_MY_PET_FAILURE:
      return { ...state, petIsRemoving: false }
    case EDIT_EMAIL_SUCCESS:
      return { ...state, user: { ...state.user, mail: [{ value: action.meta.email }] } }
    default:
      return state
  }
}
