import {
  EDIT_EMAIL_FAILURE,
  EDIT_EMAIL_POPUP_HIDE,
  EDIT_EMAIL_POPUP_SHOW,
  EDIT_EMAIL_REQUEST,
  EDIT_EMAIL_SUCCESS
} from '../actions/lk'

const initialState = {
  editEmailLoading: false,
  editEmailSuccess: false,
  editEmailFailure: false,
  editEmailPopupOpened: false
}

export const lk = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case EDIT_EMAIL_POPUP_SHOW:
      return {
        ...state,
        editEmailPopupOpened: true
      }
    case EDIT_EMAIL_POPUP_HIDE:
      return {
        ...state,
        editEmailPopupOpened: false
      }
    case EDIT_EMAIL_REQUEST:
      return {
        ...state,
        editEmailLoading: true
      }
    case EDIT_EMAIL_FAILURE:
      return {
        ...state,
        editEmailFailure: true,
        editEmailLoading: false
      }
    case EDIT_EMAIL_SUCCESS:
      return {
        ...state,
        editEmailSuccess: true,
        editEmailLoading: false
      }
    default:
      return state
  }
}
