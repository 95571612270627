import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.scss'

class Preloader extends Component {
  static propTypes = {
    type: PropTypes.string
  }

  render() {
    const { type } = this.props
    return (
      <div className={'preloader ' + type}>
        <i className='icon icon-loader' />
      </div>
    )
  }
}

export default Preloader
