import { RSAA } from 'redux-api-middleware'
// import { apiAction } from './apiAction'

export const GET_STORIES_REQUEST = 'GET_STORIES_REQUEST'
export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS'
export const GET_STORIES_FAILURE = 'GET_STORIES_FAILURE'

export const getStories = () => {
  return {
    [RSAA]: {
      endpoint: 'api/happy_stories',
      method: 'GET',
      types: [GET_STORIES_REQUEST, GET_STORIES_SUCCESS, GET_STORIES_FAILURE],
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    }
  }
}

export const GET_BLOCKS_REQUEST = 'GET_BLOCKS_REQUEST'
export const GET_BLOCKS_SUCCESS = 'GET_BLOCKS_SUCCESS'
export const GET_BLOCKS_FAILURE = 'GET_BLOCKS_FAILURE'

export const getBlocks = () => {
  return {
    [RSAA]: {
      endpoint: 'api/blocks',
      method: 'GET',
      types: [GET_BLOCKS_REQUEST, GET_BLOCKS_SUCCESS, GET_BLOCKS_FAILURE],
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    }
  }
}

export const SHOW_STORY_IN_POPUP = 'SHOW_STORY_IN_POPUP'

export const showStoryInPopup = id => ({
  type: SHOW_STORY_IN_POPUP,
  payload: {
    currentStory: id,
    isStoriesPopupOpened: true
  }
})

export const CLOSE_STORIES_POPUP = 'CLOSE_STORIES_POPUP'

export const closeStoriesPopup = id => ({
  type: CLOSE_STORIES_POPUP,
  payload: {
    isStoriesPopupOpened: false
  }
})
