import { put } from 'redux-saga/effects'
import { getArticles, getOurArticles } from '../actions/articles'

function* articlesSaga(params = []) {
  const id =
    params.id &&
    params.id.indexOf('?page=') > -1 &&
    Number.isInteger(Number(params.id.replace('?page=', '')))
      ? `?page=${Number(params.id.replace('?page=', '')) - 1}`
      : null
  yield put(getArticles(id))
}

export function* ourArticlesSaga(params = []) {
  const id =
    params.id &&
    params.id.indexOf('?page=') > -1 &&
    Number.isInteger(Number(params.id.replace('?page=', '')))
      ? `?page=${Number(params.id.replace('?page=', '')) - 1}`
      : null
  yield put(getOurArticles(id))
}

export default articlesSaga
