import {
  CLEAR_CURRENT_PET,
  GET_OTHER_PETS_REQUEST,
  GET_OTHER_PETS_SUCCESS,
  GET_PET_FAILURE,
  GET_PET_REQUEST,
  GET_PET_SUCCESS,
  GET_PETS_BY_TYPE_REQUEST,
  GET_PETS_BY_TYPE_SUCCESS,
  GET_PETS_FAILURE,
  GET_PETS_FAILURE_FAVORITES,
  GET_PETS_REQUEST,
  GET_PETS_SUCCESS,
  PARSE_URL_SUCCESS,
  REMEMBER_FILTERS_SUCCESS
} from '../actions/favorites'
import { GET_PET_CITIES_SUCCESS } from '../actions/getPetCities'
import { petParamsList } from './petParamsList'
import { CLOSE_PETS_MESSAGE, OPEN_PETS_MESSAGE } from '../actions/'

const initialState = {
  pets: [],
  count: 0,
  currentPet: undefined,
  otherPets: [],
  otherPetsPager: {},
  error: false,
  pager: {},
  params: { ...petParamsList }
}

const PETS = [
  {
    pet_name: 'Мурзик'
  }
]

export const favorites = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case '@@router/LOCATION_CHANGE':
    case GET_PETS_REQUEST:
      return { ...state }
    case GET_PETS_BY_TYPE_REQUEST:
      return { ...state, pets: [], currentPet: undefined, pager: {} }
    case CLEAR_CURRENT_PET:
      return {
        ...state,
        currentPet: undefined,
        otherPets: [],
        otherPetsPager: {}
      }
    case GET_OTHER_PETS_REQUEST:
      return { ...state }
    case GET_OTHER_PETS_SUCCESS:
      return {
        ...state,
        otherPets: action.payload.results,
        otherPetsPager: action.payload.pager
      }
    case GET_PETS_SUCCESS:
      return {
        ...state,
        pets: action.payload.results,
        pager: action.payload.pager
      }
    case GET_PETS_FAILURE:
      return {
        ...state,
        pets: PETS,
        pager: action.payload.pager
      }
    case GET_PETS_FAILURE_FAVORITES:
      return {
        ...state,
        pets: [],
        pager: action.payload.pager
      }
    case GET_PETS_BY_TYPE_SUCCESS:
      return { ...state, pets: action.payload.results }
    case GET_PET_REQUEST:
      return { ...state, currentPet: undefined }
    case GET_PET_SUCCESS:
      return { ...state, currentPet: action.payload }
    case GET_PET_FAILURE:
      return { ...state, error: true }
    case REMEMBER_FILTERS_SUCCESS:
      return { ...state, currentFiltersPreset: action.payload }
    case OPEN_PETS_MESSAGE:
      return { ...state, showResultMessage: true }
    case CLOSE_PETS_MESSAGE:
      return { ...state, showResultMessage: false }
    case GET_PET_CITIES_SUCCESS:
      const defaultVariants = [
        {
          title: 'Выберите город',
          value: 'All'
        }
      ]
      return {
        ...state,
        params: {
          ...state.params,
          cityVariants: {
            filterName: 'pet_city',
            variants: defaultVariants.concat([], action.payload)
          }
        }
      }
    case PARSE_URL_SUCCESS:
      // массив из элементов ссылки, разбитых по '/'
      const urlArray = action.payload.url
      // Создаём корректную ссылку, т.к. адрес начинается с /pets по умолчанию,
      // добавляем первый элемент массива и удаляем его
      let currentUrl = `/${urlArray[0]}`
      let currentFiltersPreset = ''
      urlArray.splice(0, 1)

      // Для того, чтобы url состоял только из разрешенных элементов и в
      // определенном порядке, создаём цикл
      const currentUrlElements = {}
      for (let key in petParamsList) {
        if (urlArray.length === 0) {
          break
        }
        const acceptedParams = {
          filterName: '',
          values: []
        }
        petParamsList[key].variants.forEach(el => {
          if (el.urlName && urlArray.indexOf(el.urlName) > -1) {
            acceptedParams.values.push(el)
            urlArray[urlArray.indexOf(el.urlName)] = ''
          }
        })
        if (acceptedParams.values.length > 0) {
          acceptedParams.filterName = petParamsList[key].filterName
          currentUrlElements[petParamsList[key].weight] = acceptedParams
        }
      }

      if (Object.keys(currentUrlElements).length > 0) {
        for (let keys in currentUrlElements) {
          for (let el in currentUrlElements[keys].values) {
            //итоговый корректный url
            currentUrl += `/${currentUrlElements[keys].values[el].urlName}`
            //значения фильтра
            currentFiltersPreset += `${currentUrlElements[keys].filterName}=${
              currentUrlElements[keys].values[el].value
            }&`
          }
        }
      }

      // если корректный url не совпадает с текущим, необходимо осуществить
      // 301й редирект.
      if (
        window.location.pathname.replace(/\//g, '') !==
        currentUrl.replace(/\//g, '')
      ) {
        window.location.replace(`${window.location.origin}${currentUrl}`)
      }

      return {
        ...state,
        currentFiltersPreset: currentFiltersPreset
      }
    default:
      return state
  }
}
