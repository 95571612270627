import { apiAction } from './apiAction'
import objectToFormData from 'object-to-formdata'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const logoutAction = () => ({
  type: LOGOUT_SUCCESS
})

export const EXTERNAL_LOGIN_SUCCESS = 'EXTERNAL_LOGIN_SUCCESS'

export const externalLoginAction = () => ({
  type: EXTERNAL_LOGIN_SUCCESS
})

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const loginAction = loginJson => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/api/v1/token',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'json_auth'
    },
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    body: JSON.stringify(loginJson),
    noAuthorization: true
  })
}

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE'

export const registrationAction = registrationFormData => (
  dispatch,
  getState
) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/registration',
    method: 'POST',
    types: [REGISTRATION_REQUEST, REGISTRATION_SUCCESS, REGISTRATION_FAILURE],
    body: registrationFormData,
    noAuthorization: true
  })
}

export const REGISTRATION_CONFIRM = 'REGISTRATION_CONFIRM'

export const registrationConfirm = values => {
  return {
    type: REGISTRATION_CONFIRM,
    payload: {
      ...values
    }
  }
}

export const PHONECONFIRM_REQUEST = 'PHONECONFIRM_REQUEST'
export const PHONECONFIRM_SUCCESS = 'PHONECONFIRM_SUCCESS'
export const PHONECONFIRM_FAILURE = 'PHONECONFIRM_FAILURE'

export const submitCodeAction = codeFormData => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/phoneConfirm',
    method: 'POST',
    types: [PHONECONFIRM_REQUEST, PHONECONFIRM_SUCCESS, PHONECONFIRM_FAILURE],
    body: codeFormData,
    noAuthorization: true
  })
}

export const RESTORE_REQUEST = 'RESTORE_REQUEST'
export const RESTORE_SUCCESS = 'RESTORE_SUCCESS'
export const RESTORE_FAILURE = 'RESTORE_FAILURE'

export const restoreAction = restoreFormData => (dispatch, getState) => {
  const data = restoreFormData
  const object = {}
  data.forEach(function(value, key) {
    object[key] = value
  })
  apiAction(dispatch, getState, {
    endpoint: '/api/user/restore',
    method: 'POST',
    types: [
      RESTORE_REQUEST,
      { type: RESTORE_SUCCESS, meta: object },
      RESTORE_FAILURE
    ],
    body: restoreFormData,
    noAuthorization: true,
    payload: restoreFormData
  })
}

export const RESTORE_COMPLETE_REQUEST = 'RESTORE_COMPLETE_REQUEST'
export const RESTORE_COMPLETE_SUCCESS = 'RESTORE_COMPLETE_SUCCESS'
export const RESTORE_COMPLETE_FAILURE = 'RESTORE_COMPLETE_FAILURE'

export const restoreCompleteAction = restoreFormData => (
  dispatch,
  getState
) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/restoreComplete',
    method: 'POST',
    types: [
      RESTORE_COMPLETE_REQUEST,
      RESTORE_COMPLETE_SUCCESS,
      RESTORE_COMPLETE_FAILURE
    ],
    body: restoreFormData,
    noAuthorization: true
  })
}

export const GET_ME_REQUEST = 'GET_ME_REQUEST'
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS'
export const GET_ME_FAILURE = 'GET_ME_FAILURE'

export const getMeAction = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/me',
    method: 'GET',
    types: [GET_ME_REQUEST, GET_ME_SUCCESS, GET_ME_FAILURE]
  })
}

export const FIND_FRIEND_REQUEST = 'FIND_FRIEND_REQUEST'
export const FIND_FRIEND_SUCCESS = 'FIND_FRIEND_SUCCESS'
export const FIND_FRIEND_FAILURE = 'FIND_FRIEND_FAILURE'

export const findFriend = friendId => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/findFriend/' + friendId,
    method: 'GET',
    types: [FIND_FRIEND_REQUEST, FIND_FRIEND_SUCCESS, FIND_FRIEND_FAILURE]
  })
}

export const EDIT_ME_REQUEST = 'EDIT_ME_REQUEST'
export const EDIT_ME_SUCCESS = 'EDIT_ME_SUCCESS'
export const EDIT_ME_FAILURE = 'EDIT_ME_FAILURE'

export const editMeAction = editMeFormData => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/update',
    method: 'POST',
    types: [EDIT_ME_REQUEST, EDIT_ME_SUCCESS, EDIT_ME_FAILURE],
    body: objectToFormData(editMeFormData)
  })
}

export const GET_MY_PET_REQUEST = 'GET_MY_PET_REQUEST'
export const GET_MY_PET_SUCCESS = 'GET_MY_PET_SUCCESS'
export const GET_MY_PET_FAILURE = 'GET_MY_PET_FAILURE'

export const getMyPetsAction = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/getpets',
    method: 'GET',
    types: [GET_MY_PET_REQUEST, GET_MY_PET_SUCCESS, GET_MY_PET_FAILURE]
  })
}

export const REMOVE_MY_PET_REQUEST = 'REMOVE_MY_PET_REQUEST'
export const REMOVE_MY_PET_SUCCESS = 'REMOVE_MY_PET_SUCCESS'
export const REMOVE_MY_PET_FAILURE = 'REMOVE_MY_PET_FAILURE'

export const removePetAction = pet => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/removepet',
    method: 'POST',
    types: [
      REMOVE_MY_PET_REQUEST,
      REMOVE_MY_PET_SUCCESS,
      REMOVE_MY_PET_FAILURE
    ],
    body: objectToFormData(pet)
  })
}

export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT'
export const REMOVE_ACCOUNT_SUCCESS = 'REMOVE_ACCOUNT_SUCCESS'
export const REMOVE_ACCOUNT_FAILURE = 'REMOVE_ACCOUNT_FAILURE'

export const removeAccount = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/delete',
    method: 'POST',
    types: [REMOVE_ACCOUNT, REMOVE_ACCOUNT_SUCCESS, REMOVE_ACCOUNT_FAILURE]
  })
}

export const GET_CONFIRM_REQUEST = 'GET_CONFIRM_REQUEST'
export const GET_CONFIRM_SUCCESS = 'GET_CONFIRM_SUCCESS'
export const GET_CONFIRM_FAILURE = 'GET_CONFIRM_FAILURE'

export const getConfirm = hash => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/confirm',
    method: 'POST',
    types: [GET_CONFIRM_REQUEST, GET_CONFIRM_SUCCESS, GET_CONFIRM_FAILURE],
    body: objectToFormData(hash),
    noAuthorization: true
  })
}

export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE'

export const loadCities = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/user/regions',
    method: 'GET',
    types: [GET_CITIES_REQUEST, GET_CITIES_SUCCESS, GET_CITIES_FAILURE]
  })
}

export const RESEND_SMS_REQUEST = 'RESEND_SMS_REQUEST'
export const RESEND_SMS_SUCCESS = 'RESEND_SMS_SUCCESS'
export const RESEND_SMS_FAILURE = 'RESEND_SMS_FAILURE'

export const resendSms = () => (dispatch, getState) => {
  const phone = {
    phone: getState().user.username
  }
  apiAction(dispatch, getState, {
    endpoint: '/api/user/restore',
    method: 'POST',
    types: [RESEND_SMS_REQUEST, RESEND_SMS_SUCCESS, RESEND_SMS_FAILURE],
    body: objectToFormData(phone),
    noAuthorization: true
  })
}
