import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

import { masked, required } from '../validationRules'
import objectToFormData from 'object-to-formdata'
import FormInput from '../formComponents/input'
import Button from '../../button'
import { restorePasswordBtnClickEvent } from "../../../utils/ga4-events"

class FirstStep extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    active: PropTypes.bool
  }

  submitFormHandler() {
    const { submitAction } = this.props
    const formData = objectToFormData(this.state.submittedValues)
    submitAction(formData)
    this.props.setPhone(this.state.submittedValues.phone)
    restorePasswordBtnClickEvent()
  }

  render() {
    return (
      <Form
        onSubmit={submittedValues => {
          this.setState({ submittedValues }, this.submitFormHandler.bind(this))
        }}
        validate={() => {
        }}
        render={({ handleSubmit, pristine, invalid }) => (
          <form onSubmit={handleSubmit}>
            <div className='form__field form__field_m-50'>
              <FormInput
                name={'phone'}
                placeholder={'Телефон'}
                mask='+79999999999'
                rules={[required, masked]}
              />
            </div>
            <div className='form__btn-wrapper'>
              <button type='submit'>
                <Button text='ВОССТАНОВИТЬ ПАРОЛЬ' type='submit' />
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

export default FirstStep
