import { apiAction } from './apiAction'

export const GET_OTHER_PETS_ACTION_D = 'GET_OTHER_PETS_ACTION_D'

export const getOtherPetsActionD = data => ({
  type: GET_OTHER_PETS_ACTION_D,
  payload: data
})

export const GET_OTHER_PETS_ACTION_SAGA = 'GET_OTHER_PETS_ACTION_SAGA'

export const getOtherPetsActionSaga = data => ({
  type: GET_OTHER_PETS_ACTION_SAGA,
  payload: data
})

export const GET_OTHER_PETS_ACTION_REMOVE = 'GET_OTHER_PETS_ACTION_REMOVE'

export const getOtherPetsActionRemove = () => ({
  type: GET_OTHER_PETS_ACTION_REMOVE
})

export const GET_PETS_REQUEST = 'GET_PETS_REQUEST'
export const GET_PETS_SUCCESS = 'GET_PETS_SUCCESS'
export const GET_PETS_FAILURE = 'GET_PETS_FAILURE'

export const getPetsAction = (filters = '', items_per_page = 12, page = 0) => (
  dispatch,
  getState
) => {
  apiAction(dispatch, getState, {
    endpoint:
      '/api/pets?' +
      encodeURI(filters) +
      (items_per_page ? '&items_per_page=' + items_per_page : '') +
      (page ? '&page=' + page : '&page=0'),
    method: 'GET',
    types: [GET_PETS_REQUEST, GET_PETS_SUCCESS, GET_PETS_FAILURE],
    noAuthorization: true
  })
}

// Получение всех животных у который статус Owner
export const GET_PETS_OWNER_REQUEST = 'GET_PETS_OWNER_REQUEST'
export const GET_PETS_OWNER_SUCCESS = 'GET_PETS_OWNER_SUCCESS'
export const GET_PETS_OWNER_FAILURE = 'GET_PETS_OWNER_FAILURE'

export const getPetsOwner = (items_per_page = 12, page = 0) => (
  dispatch,
  getState
) => {
  apiAction(dispatch, getState, {
    endpoint:
      '/api/pets/owner?' +
      (items_per_page ? '&items_per_page=' + items_per_page : '') +
      (page ? '&page=' + page : '&page=0'),
    method: 'GET',
    types: [
      GET_PETS_OWNER_REQUEST,
      GET_PETS_OWNER_SUCCESS,
      GET_PETS_OWNER_FAILURE
    ],
    noAuthorization: true
  })
}

export const GET_PETS_SIMILAR_REQUEST = 'GET_PETS_SIMILAR_REQUEST'
export const GET_PETS_SIMILAR_SUCCESS = 'GET_PETS_SIMILAR_SUCCESS'
export const GET_PETS_SIMILAR_FAILURE = 'GET_PETS_SIMILAR_FAILURE'

export const getPetsSimilar = path => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: `/api/getreference/pets/${path}`,
    method: 'GET',
    types: [
      GET_PETS_SIMILAR_REQUEST,
      GET_PETS_SIMILAR_SUCCESS,
      GET_PETS_SIMILAR_FAILURE
    ],
    noAuthorization: true
  })
}

export const GET_OTHER_PETS_REQUEST = 'GET_OTHER_PETS_REQUEST'
export const GET_OTHER_PETS_SUCCESS = 'GET_OTHER_PETS_SUCCESS'
export const GET_OTHER_PETS_FAILURE = 'GET_OTHER_PETS_FAILURE'

export const getOtherPetsAction = (
  filters = '',
  items_per_page = 20,
  page = 0
) => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint:
      '/api/pets?' +
      encodeURI(filters) +
      (items_per_page ? '&items_per_page=' + items_per_page : '') +
      (page ? '&page=' + page : '&page=0'),
    method: 'GET',
    types: [
      GET_OTHER_PETS_REQUEST,
      GET_OTHER_PETS_SUCCESS,
      GET_OTHER_PETS_FAILURE
    ],
    noAuthorization: true
  })
}

export const SET_PET_FLAG_REQUEST = 'SET_PET_FLAG_REQUEST'
export const SET_PET_FLAG_SUCCESS = 'SET_PET_FLAG_SUCCESS'
export const SET_PET_FLAG_FAILURE = 'SET_PET_FLAG_FAILURE'

export const sendPetToFavorites = id => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/petnode/' + id + '/set',
    method: 'GET',
    types: [SET_PET_FLAG_REQUEST, GET_PET_FLAG_SUCCESS, SET_PET_FLAG_FAILURE],
    noAuthorization: false
  })
}

export const GET_PET_FLAG_REQUEST = 'GET_PET_FLAG_REQUEST'
export const GET_PET_FLAG_SUCCESS = 'GET_PET_FLAG_SUCCESS'
export const GET_PET_FLAG_FAILURE = 'GET_PET_FLAG_FAILURE'

export const getPetFavoritesFlag = id => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/petnode/' + id + '/get',
    method: 'GET',
    types: [GET_PET_FLAG_REQUEST, GET_PET_FLAG_SUCCESS, GET_PET_FLAG_FAILURE],
    noAuthorization: false
  })
}

export const getMorePetsAction = (
  filters = '',
  items_per_page = 12,
  page = 0
) => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint:
      '/api/pets?' +
      encodeURI(filters) +
      (items_per_page ? '&items_per_page=' + items_per_page : '') +
      (page ? '&page=' + page : '&page=0'),
    method: 'GET',
    types: [GET_PETS_REQUEST, GET_PETS_SUCCESS, GET_PETS_FAILURE],
    noAuthorization: true
  })
}

export const PARSE_URL_SUCCESS = 'PARSE_URL_SUCCESS'

export const parseURL = url => {
  return {
    type: PARSE_URL_SUCCESS,
    payload: {
      url
    }
  }
}

export const CLEAR_CURRENT_PET = 'CLEAR_CURRENT_PET'

export const clearCurrentPet = () => ({
  type: CLEAR_CURRENT_PET
})

export const REMEMBER_FILTERS_SUCCESS = 'REMEMBER_FILTERS_SUCCESS'

export const rememberFiltersSet = (filters = '') => ({
  type: REMEMBER_FILTERS_SUCCESS,
  payload: filters
})

export const GET_PETS_BY_TYPE_REQUEST = 'GET_PETS_BY_TYPE_REQUEST'
export const GET_PETS_BY_TYPE_SUCCESS = 'GET_PETS_BY_TYPE_SUCCESS'
export const GET_PETS_BY_TYPE_FAILURE = 'GET_PETS_BY_TYPE_FAILURE'

export const getPetsByTypeAction = (type = 'active') => (
  dispatch,
  getState
) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/pets/' + type,
    method: 'GET',
    types: [
      GET_PETS_BY_TYPE_REQUEST,
      GET_PETS_BY_TYPE_SUCCESS,
      GET_PETS_BY_TYPE_FAILURE
    ],
    noAuthorization: true
  })
}

export const GET_PET_REQUEST = 'GET_PET_REQUEST'
export const GET_PET_SUCCESS = 'GET_PET_SUCCESS'
export const GET_PET_FAILURE = 'GET_PET_FAILURE'

export const getPetAction = id => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/pet/' + id,
    method: 'GET',
    types: [GET_PET_REQUEST, GET_PET_SUCCESS, GET_PET_FAILURE],
    noAuthorization: true
  })
}

export const GET_PET_CITIES_REQUEST = 'GET_PET_CITIES_REQUEST'
export const GET_PET_CITIES_SUCCESS = 'GET_PET_CITIES_SUCCESS'
export const GET_PET_CITIES_FAILURE = 'GET_PET_CITIES_FAILURE'

export const getPetCities = () => (dispatch, getState) => {
  apiAction(dispatch, getState, {
    endpoint: '/api/cities',
    method: 'GET',
    types: [
      GET_PET_CITIES_REQUEST,
      GET_PET_CITIES_SUCCESS,
      GET_PET_CITIES_FAILURE
    ],
    noAuthorization: true
  })
}

export const ADD_VISIT_PAGE_PET = 'ADD_VISIT_PAGE_PET'

export const addVisitPagePet = () => ({
  type: ADD_VISIT_PAGE_PET
})

export const OPEN_PET_ANSWER_POPUP = 'OPEN_PET_ANSWER_POPUP'

export const openPetAnswerPopup = () => ({
  type: OPEN_PET_ANSWER_POPUP
})

export const CLOSE_PET_ANSWER_POPUP = 'CLOSE_PET_ANSWER_POPUP'

export const closePetAnswerPopup = () => ({
  type: CLOSE_PET_ANSWER_POPUP
})
